import { styled } from 'styled-components';

import CommonIcon from '../../../Icon';

// Wrapper around the entire component
export const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

// Label text
export const Text = styled.p`
  margin-left: var(--spacing-tiny);
`;

// Icon with additional styling
export const Icon = styled(CommonIcon)`
  flex-shrink: 0;
`;
