import React, { FC } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { MODAL__POST_DELETE } from '../../../constants/routeModalParams';
import { getOpenModalUrl } from '../../../containers/modals/helpers';
import { Message } from '../../../generated/graphql';
import translate from '../../../i18n/translate';

import ButtonIcon from '../../Common/ButtonIcon';

import MessageSectionActions from '../MessageSectionActions';

import * as Styled from './styled';

export type Props = {
  messageId: Message['id'];
  shouldShowContent: boolean;
  shouldUseIcon?: boolean;
};

const ICON_SIZE = 19;

/**
 * Message section which returns delete button if user is authorized to delete the message.
 * If button is clicked, confirmation modal pops up.
 *
 * @param props                   Props passed to the component
 * @param props.messageId         Id of message
 * @param props.shouldShowContent Tells should component return its content or null
 * @param props.shouldUseIcon     If to use a delete icon instead of a delete button
 * @returns                       The component itself or null
 */
const MessageDelete: FC<Props> = ({
  messageId,
  shouldShowContent,
  shouldUseIcon = false,
}) => {
  const location = useLocation();
  const label = translate('GENERAL__REMOVE');

  if (shouldShowContent === false) {
    return null;
  }

  const modalUrl = getOpenModalUrl(location, MODAL__POST_DELETE, { messageId });

  return (
    <>
      {shouldUseIcon ? (
        <Link tabIndex={-1} to={modalUrl}>
          <ButtonIcon
            color="var(--color-icons-inactive)"
            colorHover="var(--color-button-danger-hover)"
            iconHeight={ICON_SIZE}
            iconName="deleteOutlined"
            iconWidth={ICON_SIZE}
            label={label}
          />
        </Link>
      ) : (
        <MessageSectionActions>
          <Link to={modalUrl}>
            <Styled.ButtonDelete shouldShowIcon={true} />
          </Link>
        </MessageSectionActions>
      )}
    </>
  );
};

export default MessageDelete;
