import React, { FC, ReactNode } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';

import MessageSectionHeading from './MessageSectionHeading';

import * as Styled from './styled';

export type Props = {
  children: ReactNode;
  className?: string;
  heading: ReactNode;
  id: string;
  linkTo?: string;
};

const CHEVRON_ICON_SIZE = 20;
const EDIT_ICON_SIZE = 28;

/**
 * Wraps a section for the message
 * (checklist, createdBy, dateDue, description, readBy, label, title)
 *
 * @param props           Props passed to the component
 * @param props.children  Section contents
 * @param props.className styled-components generated class name
 * @param props.id        Html element id
 * @param props.heading   What to render as the section heading (usually just a heading string)
 * @param props.linkTo    Location for Link component *
 * @returns               The component itself
 */
const MessageSection: FC<Props> = ({
  children,
  className,
  heading,
  id,
  linkTo,
}) => {
  const isMobile = useIsMobile();

  /**
   * If linkTo is undefined, user has no permission to change
   * specific section, so just show the data and make it not clickable
   */
  if (linkTo === undefined) {
    return (
      <Styled.Wrapper className={className} id={id}>
        <Styled.HeadingWrapperStatic>
          <Styled.Heading>{heading}</Styled.Heading>
        </Styled.HeadingWrapperStatic>
        <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>
      </Styled.Wrapper>
    );
  }

  if (isMobile) {
    return (
      <Styled.WrapperClickable className={className} id={id} to={linkTo}>
        <Styled.Content>
          <Styled.HeadingWrapper>
            <Styled.Heading>{heading}</Styled.Heading>
          </Styled.HeadingWrapper>
          {children}
        </Styled.Content>
        <Styled.ChevronIcon
          color="var(--color-text-light)"
          direction="right"
          size={CHEVRON_ICON_SIZE}
        />
      </Styled.WrapperClickable>
    );
  }

  return (
    <Styled.Wrapper id={id}>
      <Styled.Content>
        <MessageSectionHeading linkTo={linkTo}>
          <Styled.Heading>{heading}</Styled.Heading>
          <Styled.EditIcon
            color="action"
            height={EDIT_ICON_SIZE}
            icon="createOutlined"
            name="edit"
            width={EDIT_ICON_SIZE}
          />
        </MessageSectionHeading>
        <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default MessageSection;
