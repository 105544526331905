/**
 * @file contains the root reducer
 */

import { combineReducers, Reducer, UnknownAction } from 'redux';

import { DebugState } from '../../models/debug';
import { DraftsState } from '../../models/draft';
import { DraftAttachmentState } from '../../models/draftAttachment';
import { MarkAsReadState } from '../../models/markAsRead';
import { NativeAppState } from '../../models/nativeApp';
import { RecurringState } from '../../models/recurring';
import { ScrollState } from '../../models/scroll';
import { SubscriptionsState } from '../../models/subscriptions';
import { TranslationsState } from '../../models/translations';

import draftsAttachments from './attachments';
import debug from './debug';
import drafts from './drafts';
import markAsRead from './markAsRead';
import nativeApp from './nativeApp';
import draftRecurring from './recurring';
import scroll from './scroll';
import subscriptions from './subscriptions';
import translations from './translations';

export type State = {
  debug: DebugState;
  drafts: DraftsState;
  draftsAttachments: DraftAttachmentState;
  draftRecurring: RecurringState;
  markAsRead: MarkAsReadState;
  nativeApp: NativeAppState;
  scroll: ScrollState;
  subscriptions: SubscriptionsState;
  translations: TranslationsState;
};

/**
 * Create the root reducer by combining all the child reducers
 * from 'reducers' subfolder
 *
 * @returns App state
 */
const rootReducer = (): Reducer<State, UnknownAction> =>
  combineReducers({
    debug,
    draftRecurring,
    drafts,
    draftsAttachments,
    markAsRead,
    nativeApp,
    scroll,
    subscriptions,
    translations,
  });

export default rootReducer;
