import React, { FC } from 'react';

import useIsMobile from '../../../../../hooks/useIsMobile';
import ButtonControl from '../ButtonControl';

import * as Styled from './styled';

type Props = {
  isDisabled: HTMLButtonElement['disabled'];
  label: string;
};

/**
 * Button to send the message
 *
 * @param props            Props passed to the component
 * @param props.isDisabled Whether to make the button disabled
 * @param props.label      Label passed to the ButtonControl (also used as button text)
 * @returns                The component itself
 */
const ButtonSend: FC<Props> = ({ isDisabled, label }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <ButtonControl
        iconName="send"
        isActive={false}
        isDisabled={isDisabled}
        label={label}
        type="submit"
      />
    );
  }

  return (
    <Styled.Button isDisabled={isDisabled} type="submit">
      {label}
    </Styled.Button>
  );
};

export default ButtonSend;
