import React, { FC } from 'react';

import { ToastMessageModel } from '../../../models/toastMessage';
import MuiIcon from '../../MUI/MuiIcon';

import ToastCloseButton from './ToastClose';
import * as Styled from './styled';

export type Props = Pick<
  ToastMessageModel,
  'customAction' | 'shouldShowClose'
> & {
  requestCloseToast: () => void;
};

/**
 * Custom action for the toast message
 *
 * @param props                   Props passed to the component
 * @param props.customAction      Actions to show
 * @param props.requestCloseToast Callback to close the toast
 * @param props.shouldShowClose   Whether the close button should be shown
 * @returns                       The component itself
 */
const ToastAction: FC<Props> = ({
  customAction,
  requestCloseToast,
  shouldShowClose,
}) => {
  /**
   * Handles the custom action and closes the toast
   */
  const handleCustomAction = () => {
    if (customAction?.callback) {
      customAction.callback();
    }
    requestCloseToast();
  };

  return (
    <>
      {customAction ? (
        <Styled.ActionButton
          aria-label={customAction.text}
          onClick={handleCustomAction}
          startIcon={customAction.icon && <MuiIcon icon={customAction.icon} />}
        >
          {customAction.text}
        </Styled.ActionButton>
      ) : (
        shouldShowClose && <ToastCloseButton requestClose={requestCloseToast} />
      )}
    </>
  );
};

export default ToastAction;
