import { styled } from 'styled-components';

import devices from '../../../styles/devices';

import {
  getLabelBgColor,
  getLabelBorderColor,
} from '../../../styles/getLabelColors';

type WrapperProps = {
  'data-color': string;
};

/**
 * Get the color to use for the wrapper background
 *
 * @param props The props passed to the component
 * @returns     The color to use
 */
const getBackgroundColorRegular = (props: WrapperProps) => {
  return getLabelBgColor(props['data-color']);
};

/**
 * Get the color to use for the wrapper background
 *
 * @param props The props passed to the component
 * @returns     The color to use
 */
const getBackgroundColorFocus = (props: WrapperProps) => {
  return getLabelBgColor(props['data-color'], 0.4);
};

// Label title
export const Title = styled.h5`
  flex-grow: 1;
  font-size: var(--font-sm);
  margin-left: var(--spacing-tiny);
  margin-right: var(--spacing-tiny);

  /**
   * A hack, otherwise doesn't work
   * This is to force a line break, when there's a label with a really lon 1 word
   */
  width: 100px;
`;

// Wrapper around the entire component
export const Wrapper = styled.div<WrapperProps>`
  align-items: center;
  background-color: ${getBackgroundColorRegular};
  border: 1px solid ${props => getLabelBorderColor(props['data-color'])};
  border-radius: var(--border-radius-tiny);
  display: flex;

  /* Using calc because var(--spacing-medium) is too big and var(--spacing-small) is too small for current design. */
  margin-bottom: calc(2 * var(--spacing-small));
  transition: background-color var(--preference-transition-duration-normal);
  width: 100%;

  &:focus-within {
    background-color: ${getBackgroundColorFocus};
  }

  @media ${devices.tabletS} {
    margin-bottom: var(--spacing-medium);
  }
`;

// <label> element wrapping the label text and the checkbox
export const Label = styled.label`
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding: var(--spacing-tiny) 0 var(--spacing-tiny) var(--spacing-tiny);
`;
