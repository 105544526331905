import React, { FC } from 'react';

import ToastDetailsWithFooter from './ToastDetailsWithFooter';
import ToastFooter from './ToastFooter';
import ToastHeader from './ToastHeader';
import * as Styled from './styled';
import { Props } from './types';

/**
 * Component for alerting concise messages
 *
 * Set isRef to true when calling this component as a refrerence (outside of React components).
 * In that use case, the details will not be displayed in the toast message since hooks are not working.
 *
 * @param props            Props passed to the component
 * @param props.closeToast Callback to close the toast
 * @param props.data       Data to show in the toast
 * @param props.toastProps React-toastify props
 * @param props.isInline   Whether the toast is rendered inline (no action buttons and no details)
 * @param props.type       Type of toast message
 * @returns                The component itself
 */
const ToastMessage: FC<Props> = ({
  data,
  closeToast,
  isInline,
  toastProps,
  type,
}) => {
  const { customAction, details, isRef, shouldShowClose = true, text } = data;
  const toastType = isInline ? type : toastProps.type;

  if (isInline) {
    return (
      <Styled.ToastWrapper>
        <Styled.ToastCard data-message-type={toastType}>
          <ToastHeader text={text} type={toastType} />
        </Styled.ToastCard>
      </Styled.ToastWrapper>
    );
  }

  return (
    <Styled.ToastWrapper>
      <Styled.ToastCard data-message-type={toastType}>
        <ToastHeader text={text} type={toastType} />
        {isRef ? (
          <ToastFooter
            customAction={customAction}
            requestCloseToast={closeToast}
            shouldShowClose={shouldShowClose}
          />
        ) : (
          <ToastDetailsWithFooter
            customAction={customAction}
            details={details}
            requestCloseToast={closeToast}
            shouldShowClose={shouldShowClose}
            type={toastType}
          />
        )}
      </Styled.ToastCard>
    </Styled.ToastWrapper>
  );
};

export default ToastMessage;
