import { Stack } from '@mui/material';
import React, { FC } from 'react';

import { HASH_MESSAGE__INFO__TITLE } from '../../../constants/routing/hashLinks';
import useTranslation from '../../../hooks/store/useTranslation';
import { MessageFeed } from '../../../models/message';
import { getUrlWithHash } from '../../../routes/helpers/hash';

import TaskDescription from './Description';
import * as Styled from './styled';

type Props = { task: MessageFeed; url: string };

/**
 * Content of task title and description
 *
 * @param props      Props passed to the component
 * @param props.task The task to show the text and title of
 * @param props.url  Message details URL in the feed
 * @returns          The component itself
 */
const TaskTextContent: FC<Props> = ({ task, url }) => {
  const { id, title } = task;

  const translation = useTranslation(id);
  const taskTitle = translation?.title ?? title;

  return (
    <Stack spacing={1}>
      <Styled.LinkText to={getUrlWithHash(url, HASH_MESSAGE__INFO__TITLE)}>
        <Styled.Typography component="h3" variant="body1">
          {taskTitle}
        </Styled.Typography>
      </Styled.LinkText>

      <TaskDescription task={task} url={url} />
    </Stack>
  );
};

export default TaskTextContent;
