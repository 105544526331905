import React, { FC } from 'react';

import { MODAL__POST_ATTACHMENT_DELETE } from '../../../constants/routeModalParams';
import { useModal } from '../../../containers/modals/useModal';

import translate from '../../../i18n/translate';
import {
  Attachment,
  AttachmentDraft,
  InputFileProps,
} from '../../../models/attachment';

import { TypeMessageFull } from '../../../models/message';
import { getCanUploadAttachmentExistingMessage } from '../../../utils/permissions/messageAttachments';
import AttachmentAdd from '../../Attachments/AttachmentAdd';
import AttachmentList from '../../Attachments/AttachmentList';

import MessageNoResult from '../MessageNoResult';

type Props = {
  attachments: (Attachment | AttachmentDraft)[];
  inputFileProps?: InputFileProps;
  message: TypeMessageFull;
};

/**
 * Show list of currently attached files
 *
 * @param props                Props passed to the component
 * @param props.attachments    Attachments to show
 * @param props.inputFileProps Props passed to the <input type="file" />
 * @param props.message        The message itself
 * @returns                    The component itself
 */
const MessageAttachment: FC<Props> = ({
  attachments,
  inputFileProps,
  message,
}) => {
  const { openModal } = useModal();

  /**
   * Open file picker system window
   */
  const handleFilePickerClick = () => {
    inputFileProps?.ref.current?.click();
  };

  const canUploadFile = getCanUploadAttachmentExistingMessage(message);

  if (attachments.length === 0) {
    return (
      <>
        <MessageNoResult message={translate('ATTACHMENT__MESSAGE__NONE')} />
        <AttachmentAdd
          canUploadFile={canUploadFile}
          inputFileProps={inputFileProps}
          requestFilePicker={handleFilePickerClick}
        />
      </>
    );
  }

  /**
   * Opens a modal to delete an attachment
   *
   * @param key File key
   */
  const requestFileRemove = (key: string) => {
    const messageId = message?.id;
    if (messageId !== undefined) {
      openModal(MODAL__POST_ATTACHMENT_DELETE, {
        attachmentId: key,
        messageId,
      });
    }
  };

  return (
    <>
      <AttachmentList
        attachments={attachments}
        message={message}
        mode="detailsEdit"
        requestFileRemove={requestFileRemove}
      />
      <AttachmentAdd
        canUploadFile={canUploadFile}
        inputFileProps={inputFileProps}
        requestFilePicker={handleFilePickerClick}
      />
    </>
  );
};

export default MessageAttachment;
