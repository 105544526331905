/**
 * @file contains helper functions for quickFilters
 */
import { Location } from 'react-router-dom';

import { getIsFeedKanbanPage } from '../../../../routes/helpers/pages';
import {
  getSearchParamFilterMembers,
  getSearchParamFilterQuick,
  getSearchParamFilterQuickState,
} from '../../../../routes/helpers/searchParams';

/**
 * Check whether the quick filter is open
 *
 * @param location Location object from the history
 * @returns        Whether it's true
 */
export const getIsQuickFilterOpen = (location: Location): boolean => {
  return getSearchParamFilterQuickState(location) === 'open';
};

/**
 * Check whether the filters are active on the current page
 *
 * @param location Location object from the history
 * @returns        Whether it's true
 */
export const getIsFilterActive = (location: Location): boolean => {
  const isKanban = getIsFeedKanbanPage();
  const activeFilter = isKanban
    ? getSearchParamFilterMembers(location)
    : getSearchParamFilterQuick(location);
  return activeFilter !== null;
};
