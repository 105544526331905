import React, { FC } from 'react';

import MessageSection from '..';

import { MESSAGE__MAX_TEXT_LENGTH } from '../../../../constants/forms';
import { HASH_MESSAGE__INFO__DESCRIPTION } from '../../../../constants/routing/hashLinks';
import { getMessageText } from '../../../../formatters/concierge/form';
import { formatConciergeData } from '../../../../formatters/concierge/formMessagingApi';
import { Message } from '../../../../generated/graphql';
import translate from '../../../../i18n/translate';
import { TypeMessageFull } from '../../../../models/message';
import { TranslationModel } from '../../../../models/translations';
import { getIsConcierge } from '../../../../utils/message';
import MessageConcierge from '../../../Common/MessageConcierge';
import MessageSectionText from '../MessageSectionText';

export type Props = {
  canUpdateMessage: boolean;
  message: TypeMessageFull;
  requestMessageTextUpdate: (text: Message['text']) => void;
  translation: TranslationModel;
};

/**
 * Section in the message that displays or lets user view or update the description
 *
 * @param props                          Props passed to the component
 * @param props.canUpdateMessage         Whether that user has the permission to edit the message
 * @param props.message                  The message we want to show the description of
 * @param props.requestMessageTextUpdate Callback that updates the description
 * @param props.translation              Translation for text
 * @returns                              The component itself
 */
const MessageSectionDescription: FC<Props> = ({
  canUpdateMessage,
  message,
  requestMessageTextUpdate,
  translation,
}) => {
  const heading = translate('GENERAL__DESCRIPTION');

  if (getIsConcierge(message.type)) {
    const data = formatConciergeData(message.text, message.thread);
    return data === null ? null : (
      <MessageSection heading={heading} id={HASH_MESSAGE__INFO__DESCRIPTION}>
        <MessageConcierge data={data} />
      </MessageSection>
    );
  }

  const messageText = translation?.text ?? getMessageText(message);

  return (
    <MessageSectionText
      canUpdateMessage={canUpdateMessage}
      id={HASH_MESSAGE__INFO__DESCRIPTION}
      maxLength={MESSAGE__MAX_TEXT_LENGTH}
      mode="description"
      requestMessageTextUpdate={requestMessageTextUpdate}
      text={messageText}
    />
  );
};

export default MessageSectionDescription;
