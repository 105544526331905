import { Avatar, ListItem, ListItemText, Stack, styled } from '@mui/material';

import { Link } from 'react-router-dom';

import { colorPrimary } from '../../../../styles/mui/colors';
import {
  colorPrimaryFocusStyle,
  colorPrimaryHoverStyle,
  colorSecondaryBaseStyle,
  colorSecondaryFocusStyle,
  colorSecondaryHoverStyle,
  railListItemIconRootStyle,
} from '../../../../styles/mui/components/shared';

export const AvatarBase = styled(Avatar)({
  backgroundColor: colorPrimary[800],
  margin: '0 auto',
});

export const HotelAvatar = styled(AvatarBase)(() => ({
  '& > .MuiAvatar-img': {
    borderRadius: 'var(--border-radius-small)',
  },
  "&[aria-current='true']": {
    outline: 'var(--border-outline)',
    /**
     * @todo Replace this when we have spacing value for 2px
     * Adding small padding to make the avatar more obvious
     */
    padding: '2px',
  },

  borderRadius: 'var(--border-radius-small)',
}));

export const HotelListItem = styled(ListItem)(() => ({
  alignItems: 'center',
  flexDirection: 'column',
}));

export const RailListItemLabel = styled(ListItemText)(({ theme }) => ({
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 'var(--preference-label-line-clamp)',
  color: theme.palette.primary.contrastText,
  display: '-webkit-box',
  fontSize: 'var(--font-sm)',
  overflow: 'hidden',
  textAlign: 'center',
  textWrap: 'wrap',
}));

export const StyledHotelWrapper = styled(Stack)(() => ({
  padding: 'var(--spacing-tiny) var(--spacing-small)',
  width: '100%',
}));

export const StyledLink = styled(Link)(() => ({
  '& > .MuiListItemIcon-root': {
    ...railListItemIconRootStyle,
  },
  '&:focus': {
    '& > .MuiListItemIcon-root': {
      ...colorPrimaryFocusStyle,
    },
    fontWeight: 'var(--font-weight-label-bold)',
  },
  '&:focus-visible': {
    boxShadow: 'none',
  },
  '&:hover': {
    '& > .MuiListItemIcon-root': {
      ...colorPrimaryHoverStyle,
    },
    fontWeight: 'var(--font-weight-label-bold)',
  },
  "&[aria-current='page']": {
    '& > .MuiListItemIcon-root': {
      ...colorSecondaryBaseStyle,
    },
    '&:focus-visible > .MuiListItemIcon-root': {
      ...colorSecondaryFocusStyle,
    },
    '&:hover > .MuiListItemIcon-root': {
      ...colorSecondaryHoverStyle,
    },
    fontWeight: 'var(--font-weight-label-bold)',
  },
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 'var(--font-weight-text)',
  padding: 'var(--spacing-tiny) var(--spacing-small)',
  width: '100%',
}));

export const LogoutButton = styled('button')(() => ({
  '&:hover, &:focus, &:focus-visible': {
    '& .MuiAvatar-root': {
      backgroundColor: colorPrimary[900],
    },
    '& .MuiListItemText-root': {
      fontWeight: 'var(--font-weight-label-bold)',
    },
    boxShadow: 'none',
  },
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 'var(--font-weight-text)',
  padding: 'var(--spacing-tiny) var(--spacing-small)',
  width: '100%',
}));
