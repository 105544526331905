import { Autocomplete, AutocompleteProps } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';

import translate from '../../../i18n/translate';

import * as Styled from './styled';

export type MuiSelectItem = {
  label: string;
  value: string | null;
};

type Props = {
  label: string;
  isSearchable?: boolean;
  requestChange: (newValue: MuiSelectItem) => void;
} & Omit<AutocompleteProps<MuiSelectItem, false, true, false>, 'renderInput'>;

/**
 * Basic select component using MUI Autocomplete
 *
 * NOTE: Autocomplete is used to provide a better user experience (enable search if needed and correctly position Menu)
 *
 * @param props               Props passed to the component
 * @param props.defaultValue  The default value of the select
 * @param props.isSearchable  Whether the select should be searchable
 * @param props.label         The label of the select
 * @param props.noOptionsText The text to display when no options are available
 * @param props.options       The options to display in the select
 * @param props.requestChange Callback to change the value of the select
 * @returns                   The component itself
 */
const MuiSelect: FC<Props> = ({
  defaultValue,
  label,
  isSearchable = false,
  noOptionsText,
  options,
  requestChange,
}) => {
  const initialOption = defaultValue ?? options[0];
  const [currentValue, setCurrentValue] =
    useState<MuiSelectItem>(initialOption);
  const [inputValue, setInputValue] = useState<string>(initialOption.label);

  /**
   * Handle change event
   *
   * @param _event   Event that triggered the change
   * @param newValue The new value of the select
   */
  const handleChange = (
    _event: ChangeEvent<object>,
    newValue: MuiSelectItem,
  ) => {
    setCurrentValue(newValue);
    requestChange(newValue);
  };

  return (
    <Autocomplete
      disableClearable={true}
      fullWidth={true}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      noOptionsText={
        noOptionsText ?? translate('SEARCH__NO_RESULTS__TITLE', inputValue)
      }
      onChange={handleChange}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderInput={({ inputProps, ...rest }) => (
        <Styled.TextField
          {...rest}
          aria-readonly={!isSearchable}
          inputProps={{ ...inputProps, readOnly: !isSearchable }}
          label={label}
        />
      )}
      selectOnFocus={isSearchable}
      value={currentValue}
    />
  );
};

export default MuiSelect;
