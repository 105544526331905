import { styled } from 'styled-components';

import CommonButton from '../Button';

import {
  getColor,
  getColorBackground,
  getColorBorder,
  getColorHover,
} from './helpers';

import { ButtonProps } from './types';

// The button itself, with disabled and hover styles
export const Button = styled(CommonButton)<ButtonProps>`
  background-color: ${getColorBackground};
  border: solid 1px ${getColorBorder};
  border-radius: var(--border-radius-tiny);
  color: ${getColor};
  height: 100%;
  padding: var(--spacing-medium) var(--spacing-big);
  transition:
    background-color var(--preference-transition-duration-normal),
    color var(--preference-transition-duration-normal);

  &:disabled {
    background-color: var(--color-button-disabled);
    border-color: var(--color-button-disabled);
    color: var(--color-text-alt);

    /* unsupported on iOS, but doesn't matter since that's touch input anyway */
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover {
      background-color: ${getColorHover};
      border-color: ${getColorHover};
      color: var(--color-text-alt);
    }
  }
`;
