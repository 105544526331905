import {
  Typography as MuiTypography,
  styled,
  TypographyProps,
} from '@mui/material';

import CustomHashLink, { CustomHashLinkProps } from '../CustomHashLink';

type Props = {
  'data-line-clamp'?: string;
};

export const LinkText = styled(CustomHashLink)<CustomHashLinkProps & Props>(
  props => {
    const lineClamp =
      props['data-line-clamp'] ??
      'var(--preference-task-description-line-clamp)';

    return {
      '&:focus-visible, &:hover': {
        textDecoration: 'underline',
      },
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: lineClamp,
      boxOrient: 'vertical',
      display: '-webkit-box',
      lineClamp: lineClamp,
      lineHeight: 1.2,
      overflow: 'hidden',
    };
  },
);

export const Typography = styled(MuiTypography)<TypographyProps>(
  ({ component }) => ({
    fontWeight: component === 'h3' ? 600 : undefined,
    wordBreak: 'break-word',
  }),
);
