import React, { FC } from 'react';

import { File as CustomFile } from '../../../generated/graphql';

import {
  Attachment,
  AttachmentDraft,
  AttachmentMode,
} from '../../../models/attachment';

import { TypeMessageFull } from '../../../models/message';

import {
  getCanDeleteAttachment,
  getCanDownloadAttachment,
} from '../../../utils/permissions/messageAttachments';

import AttachmentSingleDraft from './AttachmentSingleDraft';
import AttachmentSingleExisting from './AttachmentSingleExisting';
import { handleFileRemove } from './helpers';

export type Props = {
  attachment: Attachment | AttachmentDraft;
  message: TypeMessageFull;
  mode: AttachmentMode;
  requestFileRemove?: (key: CustomFile['key']) => void;
};

/**
 * Component that renders single attachment
 *
 * @param props                   Props passed to the component
 * @param props.attachment        File that is attached
 * @param props.message           The message itself
 * @param props.mode              In which mode to render the component
 * @param props.requestFileRemove Callback for removing file from the drafts array or message
 * @returns                       The component itself
 */
const AttachmentSingle: FC<Props> = ({
  attachment,
  message,
  mode,
  requestFileRemove,
}) => {
  /**
   * Whether user has permission to delete/download attachment
   */
  const canDeleteFile = getCanDeleteAttachment(message);
  const canDownloadFile = getCanDownloadAttachment(message);
  /**
   * Remove file from draft or call delete mutation.
   * Depends on attachment status (draft or existing)
   *
   */
  const handleButtonClick = () => {
    handleFileRemove(attachment, canDeleteFile, requestFileRemove);
  };

  // Check if attachment is draft (message that is not created yet)
  if (Object.hasOwn(attachment, 'isDraft')) {
    return (
      <AttachmentSingleDraft
        attachment={attachment}
        mode={mode}
        requestFileRemove={handleButtonClick}
      />
    );
  }

  return (
    <AttachmentSingleExisting
      attachment={attachment}
      canDeleteFile={canDeleteFile}
      canDownloadFile={canDownloadFile}
      message={message}
      mode={mode}
      requestFileRemove={handleButtonClick}
    />
  );
};

export default AttachmentSingle;
