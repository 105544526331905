import React, { FC } from 'react';

import translate from '../../../i18n/translate';
import MuiIcon from '../../MUI/MuiIcon';

import * as Styled from './styled';

type Props = {
  requestClose: () => void;
};

/**
 * Close button for the toast message
 *
 * @param props              The props passed to the component
 * @param props.requestClose Callback to close the toast
 * @returns                  The component itself
 */
const ToastCloseButton: FC<Props> = ({ requestClose }) => {
  const labelClose = translate('GENERAL__CLOSE');

  return (
    <Styled.ActionButton
      aria-label={labelClose}
      onClick={requestClose}
      startIcon={<MuiIcon icon="close" />}
    >
      {labelClose}
    </Styled.ActionButton>
  );
};

export default ToastCloseButton;
