import { styled } from 'styled-components';

import CommonRibbon from '../../Ribbon';

export const Ribbon = styled(CommonRibbon)`
  border-radius: var(--border-radius-huge);
  display: none;
  left: 50%;
  position: absolute;
  top: var(--spacing-small);
  transform: translateX(-50%);

  & > li {
    border-radius: var(--border-radius-huge);

    /* Target only li elements with a child because we can also have buttons (eg. Translate button) */
    &:has(a) {
      padding: var(--spacing-tiny);
    }

    /* Apply hover styles on child hover */
    /* stylelint-disable-next-line selector-class-pattern */
    &:has(a .MuiSvgIcon-root:hover) {
      background-color: var(--color-grey-pale);
    }
  }

  @media (hover: none) {
    display: flex;
  }
`;
