import React, { FC } from 'react';

import { MuiIconName, MuiSymbolIconName } from '../../../../MUI/MuiIcon/types';
import { NAMES } from '../../../ButtonIcon';

import { getIconColor } from './helpers';
import * as Styled from './styled';

type Props = {
  iconName: MuiIconName | MuiSymbolIconName;
  isActive: boolean;
  isDisabled?: HTMLButtonElement['disabled'];
  label: string;
  onClick?: () => void;
  type?: 'button' | 'submit';
};

const defaultType = 'button';

/**
 * Make control buttons same height as send button so there is no different in height
 * between comment external/internal on desktop.
 */
const ICON_SIZE = 19;

/**
 * Generic compose control button, common for all controls
 *
 * @param props            Props passed to the component
 * @param props.isDisabled Whether to make the button disabled
 * @param props.iconName   The name of the icon to use for  the control
 * @param props.isActive   Whether the control is active
 * @param props.label      Label for ButtonIcon
 * @param props.onClick    Button click callback
 * @param props.type       The button type
 * @returns                The component itself
 */
const ButtonControl: FC<Props> = ({
  iconName,
  isActive,
  isDisabled = false,
  label,
  onClick,
  type = defaultType,
}) => {
  return (
    <Styled.ButtonIcon
      aria-pressed={isActive ? 'true' : 'false'}
      color={`var(${getIconColor(isDisabled)})`}
      iconHeight={ICON_SIZE}
      iconName={iconName}
      iconWidth={ICON_SIZE}
      isDisabled={isDisabled}
      label={label}
      onClick={onClick}
      type={type}
    />
  );
};

export default ButtonControl;
export { NAMES };
