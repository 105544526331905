import { css, styled } from 'styled-components';

const commonStyles = css`
  align-items: center;
  display: flex;
  font-weight: var(--font-semibold);
  padding: var(--spacing-medium) 0;
  width: 50%;
`;

export const TasksWrapper = styled.div`
  border: var(--border-dark);
  border-radius: var(--border-radius-big);
  display: flex;
  margin-bottom: var(--spacing-small);
`;

export const TaskDataWrapper = styled.ul`
  flex-direction: column;
  ${commonStyles}
`;

export const TaskImageWrapper = styled.div`
  background-color: var(--color-background-list);
  border-bottom-right-radius: inherit;
  border-top-right-radius: inherit;
  justify-content: center;
  ${commonStyles}
`;

export const KpiWrapper = styled.li`
  width: 65%;
`;
