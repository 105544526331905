/**
 * @file Contains customization for MuiFilledInput Component
 */

import { Components } from '@mui/material';

const MuiFilledInput: Components['MuiFilledInput'] = {
  styleOverrides: {
    input: {
      '&::-webkit-search-cancel-button': {
        cursor: 'pointer',
        // Hack to make it dark as browser does not allow to change color directly
        filter: 'brightness(0)',
      },
      // Have to use !important due to global applied styles on input elements
      // Replace global after all new designs are applied
      border: 'none !important',
      padding: 'var(--spacing-medium)',
    },
    root: {
      '&:has(:not(:required):invalid, :required:not([value=""]):invalid)': {
        border: 'var(--border-danger)',
      },
      borderRadius: 'var(--border-radius-huge)',
      paddingLeft: 'var(--spacing-medium)',
    },
  },
};

export default MuiFilledInput;
