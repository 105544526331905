/**
 * @file Helper functions to generate links for settings navigation
 */

import { Hotel } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import { getProfileExternalUrl } from '../../../routes/external';
import { createDevPanelPath } from '../../../routes/paths/devPanel';
import {
  createSettingsGroupsPath,
  createSettingsLabelsPath,
  createSettingsNotificationsEmailPath,
  createSettingsNotificationsEmailSinglePath,
  createSettingsNotificationsPath,
  createSettingsNotificationsPushPath,
  createSettingsNotificationsPushSinglePath,
  createSettingsPeoplePath,
  createSettingsTemplateEditPath,
  createSettingsTemplatesPath,
  createSettingsTopicsPath,
  createSettingsTranslationPath,
} from '../../../routes/paths/settings';
import { getDevPanelUrl } from '../../../routes/urls/devPanel';
import {
  getSettingsGroupsUrl,
  getSettingsLabelsUrl,
  getSettingsNotificationsPushUrl,
  getSettingsPeopleUrl,
  getSettingsTemplatesUrl,
  getSettingsTopicsUrl,
  getSettingsTranslationUrl,
} from '../../../routes/urls/settings';
import { getCanViewDevPanel } from '../../../utils/permissions/devPanel';
import { getUserCurrent } from '../../../utils/permissions/storage';
import { getCanViewTemplatesTable } from '../../../utils/permissions/template';
import { getCanViewUsersTable } from '../../../utils/permissions/user';
import { getIsReactNativeWebView } from '../../../utils/webview/helpers';
import { IconName } from '../../MUI/MuiIcon/mapping';

export type LinkItem = {
  icon: IconName;
  isExternal?: boolean;
  matchUrl?: string[];
  label: string;
  url: string;
  visibility: 'desktop' | 'mobile' | 'both' | 'none';
};

/**
 * Links to serve in the Settings navigation
 *
 * @param hotelId  ID of the hotel
 * @param isMobile Whether the user is ona small screen device
 * @param isTablet Whether the device is a tablet
 * @returns        Array of settings links
 */
export const generateLinks = (
  hotelId: Hotel['id'] | null,
  isMobile: boolean,
  isTablet: boolean,
): LinkItem[] => {
  const linksInternal: LinkItem[] = [
    {
      icon: 'topic',
      label: 'SETTINGS__TOPICS',
      matchUrl: [createSettingsTopicsPath()],
      url: getSettingsTopicsUrl(hotelId, isTablet),
      visibility: 'both',
    },
    {
      icon: 'template',
      label: 'SETTINGS__TEMPLATES',
      matchUrl: [
        createSettingsTemplateEditPath(),
        createSettingsTemplatesPath(),
      ],
      url: getSettingsTemplatesUrl(hotelId, isTablet),
      visibility: getCanViewTemplatesTable() ? 'both' : 'none',
    },
    {
      icon: 'translate',
      label: 'SETTINGS__AUTOMATIC_TRANSLATIONS',
      matchUrl: [createSettingsTranslationPath()],
      url: getSettingsTranslationUrl(hotelId, isTablet),
      visibility: getCanViewUsersTable() === false ? 'both' : 'none',
    },
    {
      icon: 'user',
      label: 'SETTINGS__PEOPLE',
      matchUrl: [createSettingsPeoplePath()],
      url: getSettingsPeopleUrl(hotelId, isTablet),
      visibility: getCanViewUsersTable() ? 'both' : 'none',
    },
    {
      icon: 'userGroup',
      label: 'SETTINGS__GROUPS',
      matchUrl: [createSettingsGroupsPath()],
      url: getSettingsGroupsUrl(hotelId, isTablet),
      visibility: 'both',
    },
    {
      icon: 'label',
      label: 'SETTINGS__LABELS',
      matchUrl: [createSettingsLabelsPath()],
      url: getSettingsLabelsUrl(hotelId, isTablet),
      visibility: 'both',
    },
    {
      icon: 'notification',
      label: 'SETTINGS__NOTIFICATIONS',
      matchUrl: [
        createSettingsNotificationsEmailPath(),
        createSettingsNotificationsEmailSinglePath(),
        createSettingsNotificationsPath(),
        createSettingsNotificationsPushPath(),
        createSettingsNotificationsPushSinglePath(),
      ],
      url: getSettingsNotificationsPushUrl(hotelId, isTablet),
      visibility: 'both',
    },
    {
      icon: 'devPanel',
      label: 'DEBUG__DEV_PANEL__LINK',
      matchUrl: [createDevPanelPath()],
      url: getDevPanelUrl(hotelId),
      visibility: getCanViewDevPanel() ? 'desktop' : 'none',
    },
  ];

  const showFor = new Set(['both', isMobile ? 'mobile' : 'desktop']);
  return linksInternal.filter(({ visibility = 'both' }) =>
    showFor.has(visibility),
  );
};

/**
 * Generate external links to show on the settings page
 *
 * @returns The links to show
 */
export const generateLinksExternal = (): LinkItem[] => [
  {
    icon: 'profile',
    isExternal: true,
    label: 'GENERAL__PROFILE',
    url: getProfileExternalUrl(getUserCurrent()?.email ?? ''),
    visibility: 'both',
  },
  {
    icon: 'help',
    isExternal: true,
    label: 'GENERAL__HELP',
    url: translate('GENERAL__HELPCENTER'),
    visibility: 'both',
  },
  {
    icon: 'copyright',
    isExternal: true,
    label: 'SETTINGS__LEGAL__NOTICE',
    url: translate(
      getIsReactNativeWebView()
        ? 'SETTINGS__LEGAL__NOTICE__URL__APP'
        : 'SETTINGS__LEGAL__NOTICE__URL__WEB',
    ),
    visibility: 'both',
  },
  {
    icon: 'privacy',
    isExternal: true,
    label: 'SETTINGS__PRIVACY__POLICY',
    url: translate(
      getIsReactNativeWebView()
        ? 'SETTINGS__PRIVACY__POLICY__URL__APP'
        : 'SETTINGS__PRIVACY__POLICY__URL__WEB',
    ),
    visibility: 'both',
  },
];
