// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --border-radius-big: 16px;
  --border-radius-huge: 100px;
  --border-radius-large: 24px;
  --border-radius-medium: 12px;
  --border-radius-small: 8px;
  --border-radius-tiny: 4px;

  --spacing-tiny: 4px;
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-big: 24px;
  --spacing-large: 32px;
  --spacing-huge: 64px;

  --height-full: 100vh;

  --size-badge-circle: 0.625rem;
  --size-badge-notification: 18px;

  /*
   * When the mobile bottom nav link is active, the chip component becomes visible.
   */
  --mobile-bottom-nav-chip-width: 64px;
}

/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
@supports (height: 100svh) {
  :root {
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    --height-full: calc(100svh - env(safe-area-inset-bottom));
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/_dimensions.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,2BAA2B;EAC3B,4BAA4B;EAC5B,0BAA0B;EAC1B,yBAAyB;;EAEzB,mBAAmB;EACnB,oBAAoB;EACpB,sBAAsB;EACtB,mBAAmB;EACnB,qBAAqB;EACrB,oBAAoB;;EAEpB,oBAAoB;;EAEpB,6BAA6B;EAC7B,+BAA+B;;EAE/B;;IAEE;EACF,oCAAoC;AACtC;;AAEA,uEAAuE;AACvE;EACE;IACE,uEAAuE;IACvE,yDAAyD;EAC3D;AACF","sourcesContent":[":root {\n  --border-radius-big: 16px;\n  --border-radius-huge: 100px;\n  --border-radius-large: 24px;\n  --border-radius-medium: 12px;\n  --border-radius-small: 8px;\n  --border-radius-tiny: 4px;\n\n  --spacing-tiny: 4px;\n  --spacing-small: 8px;\n  --spacing-medium: 16px;\n  --spacing-big: 24px;\n  --spacing-large: 32px;\n  --spacing-huge: 64px;\n\n  --height-full: 100vh;\n\n  --size-badge-circle: 0.625rem;\n  --size-badge-notification: 18px;\n\n  /*\n   * When the mobile bottom nav link is active, the chip component becomes visible.\n   */\n  --mobile-bottom-nav-chip-width: 64px;\n}\n\n/* stylelint-disable-next-line plugin/no-unsupported-browser-features */\n@supports (height: 100svh) {\n  :root {\n    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */\n    --height-full: calc(100svh - env(safe-area-inset-bottom));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
