import React, { FC } from 'react';

import { File as CustomFile } from '../../../generated/graphql';
import useIsMobile from '../../../hooks/useIsMobile';

import {
  Attachment,
  AttachmentDraft,
  AttachmentMode,
} from '../../../models/attachment';

import { TypeMessageFull } from '../../../models/message';
import AttachmentSingle from '../AttachmentSingle';

import AttachmentListEmpty from './AttachmentListEmpty';

import * as Styled from './styled';

export type Props = {
  attachments: (Attachment | AttachmentDraft)[];
  message: TypeMessageFull;
  mode: AttachmentMode;
  requestFileRemove?: (key: CustomFile['key']) => void;
};

/**
 * List of attachments
 *
 * @param props                   Props passed to the component
 * @param props.attachments       Attachments to show
 * @param props.message           The message itself
 * @param props.mode              In which mode to render the component
 * @param props.requestFileRemove Callback for removing file from the array
 * @returns                       The component itself
 */
const AttachmentList: FC<Props> = ({
  attachments,
  message,
  mode,
  requestFileRemove,
}) => {
  const isMobile = useIsMobile();
  const showEmptyIllustration = mode === 'detailsEdit';

  if (isMobile && mode === 'compose') {
    return null;
  }

  if (attachments.length === 0 && showEmptyIllustration) {
    return <AttachmentListEmpty />;
  }

  return (
    <Styled.ListWrapper data-mode={mode}>
      {attachments.map(attachment => (
        <li key={attachment.key}>
          <AttachmentSingle
            attachment={attachment}
            message={message}
            mode={mode}
            requestFileRemove={requestFileRemove}
          />
        </li>
      ))}
    </Styled.ListWrapper>
  );
};

export default AttachmentList;
