import { useMove } from '@react-aria/interactions';
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { getSearchParamMessageReplies } from '../../../routes/helpers/searchParams';
import { NAMES } from '../ButtonIcon';

import Icon from '../Icon';

import {
  getDragButtonPropertiesByMode,
  getSheetModeOnMoveEnd,
} from './helpers';
import * as Styled from './styled';
import {
  PageSheetMode,
  PageSheetModeHeightMap,
  ModalPageSheetProps as Props,
} from './types';

const ICON_SIZE = 24;

/**
 * PageSheet component
 *
 * @param props              Props passed to the component
 * @param props.children     PageSheet content
 * @param props.heading      Heading shown in closed state
 * @param props.onClose      Callback that triggers on sheet closed
 * @param props.onOpen       Callback that triggers on sheet opened
 * @param props.parentHeight Height of parent element
 * @param props.step         % of parent height used for inner step (between 0 and 1)
 * @returns                  The component itself
 */
const ModalPageSheet: FC<Props> = ({
  children,
  heading,
  onClose,
  onOpen,
  parentHeight,
  step = 0.7,
}) => {
  const heightClosedRef = useRef(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const paramReplies = getSearchParamMessageReplies(location);

  /**
   * If the user has clicked on the replies link, the sheet should be open initially
   */
  const initialMode = paramReplies !== null ? 'open' : 'closed';

  /**
   * The height of the sheet while dragging up and down.
   * Zero means that there is no dragging happening.
   */
  const [heightAnimated, setHeightAnimated] = useState(0);
  const [mode, setMode] = useState<PageSheetMode>(initialMode);

  const { buttonDragLabel, buttonFullScreenLabel } =
    getDragButtonPropertiesByMode(mode);

  /**
   * Fixed heights we want to use for the sheet based on the mode
   */
  const heightByModeMap: PageSheetModeHeightMap = {
    closed: heightClosedRef.current,
    full: parentHeight - heightClosedRef.current,
    open: parentHeight * step,
  };

  const heightByMode = heightByModeMap[mode];
  const isDragging = heightAnimated !== 0;

  const currentHeight = isDragging ? heightAnimated : heightByMode;

  /**
   * Set the height of the sheet based on the mode on first render
   */
  useLayoutEffect(() => {
    heightClosedRef.current = headerRef.current?.clientHeight ?? 0;
  }, []);

  /**
   * Invoke callbacks on mode change
   */
  useEffect(() => {
    if (mode === 'open') {
      onOpen?.();
    }

    if (mode === 'closed') {
      onClose?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const { moveProps } = useMove({
    /**
     * Set height of element based on pointer position
     *
     * @param event        Move event
     * @param event.deltaY Cursor location (Y axis)
     */
    onMove({ deltaY }) {
      setHeightAnimated(y => {
        // We don't do anything if we drag down below the collapsed height.
        // `deltaY` is negative when moving up and positive when moving down.
        if (y < heightByModeMap.closed && deltaY >= 0) {
          return 0;
        }

        return y - deltaY;
      });
    },

    /**
     * Determine the mode of the sheet based on the height
     */
    onMoveEnd() {
      const nextMode = getSheetModeOnMoveEnd({
        heightAnimated,
        heightByModeMap,
        mode,
      });

      if (nextMode !== null) {
        setMode(nextMode);
      }

      setHeightAnimated(0);
    },

    /**
     * Callback that triggers when the user starts dragging
     */
    onMoveStart() {
      // We set the value so we know that the user is dragging,
      // and the transition should start
      setHeightAnimated(heightByModeMap[mode]);
    },
  });

  /**
   * Toggle between opened and closed
   */
  const toggleSheet = (): void => {
    // We don't want to toggle the sheet if the user is dragging
    // because we do it in the onMoveEnd callback.
    if (isDragging) {
      return;
    }

    setMode(prevMode => (prevMode === 'closed' ? 'open' : 'closed'));
  };

  /**
   * Toggle between full screen and inner step
   */
  const toggleFullMode = () => {
    // We don't want to toggle the sheet if the user is dragging,
    // because we do it in the onMoveEnd callback.
    if (isDragging) {
      return;
    }

    setMode(prevMode => (prevMode === 'full' ? 'open' : 'full'));
  };

  return (
    <Styled.Wrapper
      role="dialog"
      style={{
        '--height': currentHeight + 'px',
      }}
    >
      <header ref={headerRef} {...moveProps}>
        <Styled.HeaderContent>
          {/* Used to center header */}
          <div />

          <Styled.TrayButton
            aria-label={buttonDragLabel}
            disableTouchRipple={true}
            onClick={toggleSheet}
          >
            <Styled.TrayButtonContent>
              <Icon
                color="var(--color-icons-hover)"
                height={6}
                name={NAMES.GENERAL__DASH}
                width={28}
              />
              {mode === 'closed' && heading}
            </Styled.TrayButtonContent>
          </Styled.TrayButton>

          {mode !== 'closed' && (
            <Styled.ResizeButton
              color="var(--color-icons-hover)"
              iconHeight={ICON_SIZE}
              iconName="fullscreen"
              iconWidth={ICON_SIZE}
              label={buttonFullScreenLabel}
              onClick={toggleFullMode}
            />
          )}
        </Styled.HeaderContent>
      </header>
      <Styled.Content>{children}</Styled.Content>
    </Styled.Wrapper>
  );
};

export default ModalPageSheet;
