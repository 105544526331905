import { ListItemText } from '@mui/material';
import React, { FC } from 'react';

import { getPolymorphicLinkProps } from '../../../../utils/mui';
import { getIsReactNativeWebView } from '../../../../utils/webview/helpers';
import MuiIcon from '../../MuiIcon';
import { IconName } from '../../MuiIcon/mapping';

import * as Styled from '../styled';

export type NavigationLinkProps = {
  icon: IconName;
  isDisabled?: boolean;
  isExternal?: boolean;
  label: string;
  shouldReplace?: boolean;
  url: string;
};

/**
 * Navigation link component that can be reused
 *
 * @param props               Props passed to the component
 * @param props.icon          The icon to display on the left
 * @param props.isDisabled    If the link is disabled
 * @param props.isExternal    If the link is external
 * @param props.label         Label of the link
 * @param props.shouldReplace If link should be replaced or push in history
 * @param props.url           A callback that is used to set a new url for filtering or a raw url
 *
 * @returns                   Left sidebar desktop link component with styles
 */
const NavigationLink: FC<NavigationLinkProps> = ({
  icon,
  isDisabled,
  isExternal = false,
  label,
  shouldReplace = false,
  url,
}) => {
  const isWebView = getIsReactNativeWebView();

  const linkProps = getPolymorphicLinkProps({
    isExternal,
    shouldReplace,
    url,
  });

  return (
    <Styled.MuiListItem disablePadding={true}>
      <Styled.MuiListItemButton {...linkProps} disabled={isDisabled}>
        <Styled.MuiListItemIcon>
          <MuiIcon icon={icon} />
        </Styled.MuiListItemIcon>
        <ListItemText primary={label} />
        {isExternal && !isWebView && <MuiIcon icon="openInNew" />}
      </Styled.MuiListItemButton>
    </Styled.MuiListItem>
  );
};

export default NavigationLink;
