import React, { FC } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';

import MuiIcon from '../MuiIcon';

import * as Styled from './styled';

type Props = {
  badgeCount: number;
  className?: string;
  isSelected: boolean;
  requestChange: () => void;
};

/**
 * Toggle button to show/hide quick filters
 *
 * @param props               Props passed to the component
 * @param props.badgeCount    Number of selected filters (if 0 badge isn't shown)
 * @param props.className     styled-components generated class name, needed for styling
 * @param props.isSelected    Whether the button is toggled
 * @param props.requestChange Request that the button is selected/unselected
 * @returns                   The component itself
 */
const ButtonQuickFiltersToggle: FC<Props> = ({
  badgeCount,
  className,
  isSelected,
  requestChange,
}) => {
  const isMobile = useIsMobile();

  return (
    <Styled.MuiButton
      aria-label={translate('GENERAL__QUICK_FILTERS')}
      className={className}
      color="neutral"
      onClick={requestChange}
      startIcon={<MuiIcon icon="filterOutlined" />}
      variant={isSelected ? 'contained' : 'outlined'}
    >
      {!isMobile && translate('GENERAL__QUICK_FILTERS')}
      <Styled.Badge badgeContent={badgeCount} variant="dot" />
    </Styled.MuiButton>
  );
};

export default ButtonQuickFiltersToggle;
