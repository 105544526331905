import React, { FC } from 'react';
import { useQuery } from 'urql';

import Topics from '../../../../components/CollapsibleLists/Topics';
import DashboardLink from '../../../../components/Dashboard/Link';
import FloatingBottomNav from '../../../../components/FloatingBottomNav';
import AppLayout from '../../../../components/layouts/AppLayout';
import HeaderHome from '../../../../containers/header/home';
import SmartViews from '../../../../containers/smartViews';
import { MeDocument, TopicsListQuery } from '../../../../generated/graphql';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import { reportError } from '../../../../services/reporting';
import { getHotelPermissionsById } from '../../../../utils/hotel';
import { getCanViewDashboard } from '../../../../utils/permissions/dashboard';

import * as Styled from './styled';

type Props = {
  isLoading: boolean;
  topics: TopicsListQuery['topics'];
};

/**
 * Home page for mobile screens
 *
 * @param props           Props passed to the component
 * @param props.isLoading Whether the topics are being loaded
 * @param props.topics    The list of topics
 * @returns               Home page for mobiles
 */
const HomeMobile: FC<Props> = ({ isLoading, topics }) => {
  /**
   * User data should be fetched at this point, so this is
   * just fetching from the cache
   */
  const [{ data }] = useQuery({
    query: MeDocument,
    requestPolicy: 'cache-only',
    variables: {},
  });
  const hotelId = useParamHotelId();
  const shouldShowDashboardLink = getCanViewDashboard();

  // Errors to be handled
  if (!data) {
    return <div>{'No user data available, please contact support'}</div>;
  }

  const { hotelPermissions } = data.me;

  const hotelPermission = getHotelPermissionsById(hotelId, hotelPermissions);

  if (hotelPermission === null) {
    /** @todo handle this error somehow */
    reportError('HomeMobile: Could not find your hotel.');
    return null;
  }

  return (
    <AppLayout footer={<FloatingBottomNav />} header={<HeaderHome />}>
      <Styled.Wrapper>
        {shouldShowDashboardLink && <DashboardLink />}
        <Topics isLoading={isLoading} topics={topics} />
        <SmartViews />
      </Styled.Wrapper>
    </AppLayout>
  );
};

export default HomeMobile;
