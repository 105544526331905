/**
 * @file contains functions used to create paths for use in Route related to the settings page
 */

import {
  ROUTE__SETTINGS,
  ROUTE__SETTINGS__EMAIL,
  ROUTE__SETTINGS__NOTIFICATIONS,
  ROUTE__SETTINGS__PUSH,
  ROUTE__SETTINGS__RECURRING,
} from '../../constants/routing/routes';

import { createDashboardPath } from './dashboard';

/**
 * Generate a path for the settings page
 *
 * @returns The generated path
 */
export const createSettingsPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS}`;
};

/**
 * Generate a path for the settings groups page
 *
 * @returns The generated path
 */
export const createSettingsGroupsPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS}/groups`;
};

/**
 * Generate a path for the settings integrations page
 *
 * @returns The generated path
 */
export const createSettingsIntegrationsPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS}/integrations`;
};

/**
 * Generate a path for the settings labels page
 *
 * @returns The generated path
 */
export const createSettingsLabelsPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS}/labels`;
};

/**
 * Generate a url for the settings notifications page
 *
 * @returns The generated url
 */
export const createSettingsNotificationsPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS__NOTIFICATIONS}`;
};

/**
 * Generate a path for the settings email notifications page
 *
 * @returns The generated path
 */
export const createSettingsNotificationsEmailPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS__EMAIL}`;
};

/**
 * Generate a url for the settings email notifications page with url param
 *
 * @returns The generated url
 */
export const createSettingsNotificationsEmailSinglePath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS__EMAIL}/:notificationParam`;
};

/**
 * Generate a path for the settings push notifications page
 *
 * @returns The generated path
 */
export const createSettingsNotificationsPushPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS__PUSH}`;
};

/**
 * Generate a url for the settings push notifications page with url param
 *
 * @returns The generated url
 */
export const createSettingsNotificationsPushSinglePath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS__PUSH}/:notificationParam`;
};

/**
 * Generate a path for the settings people page
 *
 * @returns The generated path
 */
export const createSettingsPeoplePath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS}/people`;
};

/**
 * Generate a path for the edit template page
 *
 * @returns The generated path
 */
export const createSettingsTemplateEditPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS}/templates/:templateId`;
};

/**
 * Generate a path for the settings templates page
 *
 * @returns The generated path
 */
export const createSettingsTemplatesPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS}/templates`;
};

/**
 * Generate a path for the settings templates recurring page
 *
 * @returns The generated path
 */
export const createSettingsTemplatesRecurringPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS}/templates/:templateId/${ROUTE__SETTINGS__RECURRING}/:frequency`;
};

/**
 * Generate a path for the settings topics page
 *
 * @returns The generated path
 */
export const createSettingsTopicsPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS}/topics`;
};

/**
 * Generate a path for the settings automatic translation page
 *
 * @returns The generated path
 */
export const createSettingsTranslationPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SETTINGS}/automatic-translation`;
};
