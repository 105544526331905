import { styled } from 'styled-components';

import { CssVariable } from '../../../../styles/cssTypes';

type WrapperProps = {
  'data-is-dimmed': boolean;
};

/**
 * Get the color to use for the message title text
 *
 * @param props Props passed
 * @returns     The color to use
 */
const getColor = (props: WrapperProps): CssVariable => {
  if (props['data-is-dimmed']) {
    return 'var(--color-text-light)';
  }

  return 'inherit';
};

// The actual message title
export const Wrapper = styled.h3<WrapperProps>`
  color: ${getColor};
  font-size: var(--font-baseline);
  font-weight: 600;
  overflow-wrap: anywhere;
`;
