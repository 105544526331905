import { CSSObject, Drawer as MuiDrawer, styled, Theme } from '@mui/material';

import breakpoints from '../../../constants/breakpoints';

const commonStyles: CSSObject = {
  flexDirection: 'row',
  overflowX: 'hidden',
};

/**
 * Generates mixin for opened drawer
 *
 * @param theme Mui theme from context
 * @returns     Styles for the drawer when it is open
 */
const openedMixin = (theme: Theme): CSSObject => ({
  ...commonStyles,
  transition: theme.transitions.create('all', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: 'var(--preference-left-sidebar-open-width-desktop)',
});

/**
 * Generates mixin for closed drawer
 *
 * @param theme Mui theme from context
 * @returns     Styles for the drawer when it is closed
 */
const closedMixin = (theme: Theme): CSSObject => ({
  ...commonStyles,
  transition: theme.transitions.create('all', {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: 'var(--preference-left-sidebar-closed-width-desktop)',
});

export const Drawer = styled(MuiDrawer, {
  // eslint-disable-next-line jsdoc/require-jsdoc
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  boxSizing: 'border-box',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  ...(open === true && {
    ...openedMixin(theme),
    [theme.breakpoints.down(breakpoints.laptopS)]: {
      width: 'var(--preference-left-sidebar-closed-width-desktop)',
    },
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      [theme.breakpoints.down(breakpoints.laptopS)]: {
        boxShadow: 'var(--elevation-4)',
      },
    },
  }),
  ...(open === false && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
