import { css, styled } from 'styled-components';

import {
  borderFocusStyle,
  resetShadowFocusStyle,
  visuallyHidden,
} from '../../../styles/common';

type ComboBoxProps = {
  'data-open': boolean;
  'data-upwards': boolean;
};

type LabelTextProps = {
  'data-is-visible': boolean;
};

type ListProps = {
  'data-label-height': number;
  'data-upwards': boolean;
  'data-open': boolean;
};

type WrapperProps = {
  'data-open': boolean;
};

/**
 * Get additional styles to apply to the comboBox based on its direction
 *
 * @param props Props passed to the comboBox
 * @returns     The CSS to apply (if any)
 */
const getComboBoxDirectionStyles = (props: ComboBoxProps) => {
  if (props['data-open'] === false) {
    return null;
  }

  if (props['data-upwards'] === true) {
    return css`
      border-top-left-radius: unset;
      border-top-right-radius: unset;
    `;
  }

  return css`
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  `;
};

/**
 * Get additional styles to apply to the label based on its state
 *
 * @param props Props passed to the label
 * @returns     The CSS to apply
 */
const getLabelVisibleStyles = (props: LabelTextProps) => {
  if (props['data-is-visible'] === true) {
    return css`
      display: block;
    `;
  } else {
    return visuallyHidden;
  }
};

/**
 * Get additional styles to apply to the list based on its direction
 *
 * @param props Props passed to the list
 * @returns     The CSS to apply (if any)
 */
const getListDirectionStyles = (props: ListProps) => {
  if (props['data-upwards'] === true) {
    return css<ListProps>`
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      border-top: var(--border-dark);
      border-top-left-radius: var(--border-radius-tiny);
      border-top-right-radius: var(--border-radius-tiny);
      bottom: ${listProps =>
        `calc(100% - ${listProps['data-label-height']}px)`};
      top: auto;
    `;
  }

  return css`
    border-bottom: var(--border-dark);
    border-bottom-left-radius: var(--border-radius-tiny);
    border-bottom-right-radius: var(--border-radius-tiny);
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    bottom: auto;
    top: 100%;
  `;
};

/**
 * Get additional styles to apply to the list based on its state
 *
 * @param props Props passed to the list
 * @returns     The CSS to apply (if any)
 */
const getListOpenStyles = (props: ListProps) => {
  if (props['data-open'] === true) {
    return css`
      opacity: 1;
      visibility: visible;
    `;
  }

  return css`
    opacity: 0;
    visibility: hidden;
  `;
};

/**
 * Get additional styles to apply to the wrapper based on its state
 *
 * @param props Props passed to the wrapper
 * @returns     The CSS to apply (if any)
 */
const getWrapperOpenStyles = (props: WrapperProps) => {
  if (props['data-open'] === true) {
    return css`
      overflow: visible;
    `;
  }

  return css`
    overflow: hidden;
  `;
};

export const Wrapper = styled.div<WrapperProps>`
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${getWrapperOpenStyles}
  position: relative;

  &[aria-disabled='true'] {
    /* unsupported on iOS, but doesn't matter since that's touch input anyway */
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const ComboBox = styled.div<ComboBoxProps>`
  align-items: center;
  border: var(--border-dark);
  border-radius: var(--border-radius-tiny);
  display: flex;
  font-size: var(--font-size-base);
  justify-content: space-between;
  ${getComboBoxDirectionStyles}
  ${borderFocusStyle}
  /**
  * The border color is used for focus styles
  */
  outline: none;
  padding: var(--spacing-tiny) var(--spacing-small);
  position: relative;
  text-align: left;
  transition:
    border-color var(--preference-transition-duration-normal),
    padding-left var(--preference-transition-duration-normal);

  [aria-disabled='true'] & {
    border-color: transparent;
    padding-left: 0;
  }
`;

export const List = styled.ul<ListProps>`
  background-color: var(--color-background);
  border-left: var(--border-dark);
  border-right: var(--border-dark);
  flex-direction: column;
  max-height: 50vh;
  ${getListDirectionStyles}
  ${getListOpenStyles}
  overflow-y: auto;

  /* Not supported on iOS Safari 15.6, but it doesn't matter, as the default behavior will work */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  overscroll-behavior-y: contain;
  position: absolute;
  width: 100%;
  z-index: var(--z-select-dropdown);
`;

export const ListItem = styled.li`
  cursor: pointer;

  /* Destroy the default focus styles for most of the app's elements */
  ${resetShadowFocusStyle}
  padding: var(--spacing-small);
  text-align: left;
  width: 100%;

  &:focus {
    background-color: var(--color-hover);
  }
`;

export const Label = styled.label<LabelTextProps>`
  color: var(--color-text-light);
  ${props => getLabelVisibleStyles(props)}
  font-size: var(--font-sm);
  font-weight: var(--font-weight-label-bold);
  padding-bottom: var(--spacing-tiny);
`;

export const Placeholder = styled.span`
  color: var(--color-text-light);
`;

export const NoItemsWrapper = styled.div`
  color: var(--color-text-light);
  font-size: var(--font-sm);
  font-style: italic;
  padding: var(--spacing-small);
`;

export const HiddenSelect = styled.select`
  bottom: 0;
  left: 50%;
  ${visuallyHidden}
  position: absolute;
`;
