import { styled } from 'styled-components';

import devices from '../../../styles/devices';

export const Form = styled.form`
  padding: 0;
  padding-top: var(--spacing-medium);
  width: 100%;

  @media ${devices.tabletS} {
    padding-left: var(--spacing-medium);
    padding-top: var(--spacing-big);

    /* @todo: Don't hardcode width */
    width: 20rem;
  }
`;
