import React, { FC, ReactNode, useEffect, useId } from 'react';
import { LinkProps } from 'react-router-dom';

import { File as CustomFile } from '../../generated/graphql';

import translate from '../../i18n/translate';
import { AttachmentDraft, InputFileProps } from '../../models/attachment';
import postPermissionCameraEvent from '../../native-messaging/handlers/web/permissionCamera';
import intlPluralFormatter from '../../utils/intlPluralFormat';
import { getIsReactNativeAndroid } from '../../utils/webview/helpers';
import AttachmentList from '../Attachments/AttachmentList';
import InputFileAttachment from '../Common/InputFileAttachment';

import MetaContainerInline, {
  Props as PropsInline,
} from '../MetaContainerInline';
import MetaContainerPopup, { Props as PropsPopup } from '../MetaContainerPopup';

type Props = {
  className?: string;
  dataAttachments: AttachmentDraft[];
  dataInputFileProps: InputFileProps;
  inputCustom?: ReactNode;
  isActive: boolean;
  linkToggleProps?: LinkProps;
  requestAttachmentClose?: () => void;
  requestAttachmentRemove: (key: CustomFile['key']) => void;
};

/**
 * Attachment popup for the compose block
 *
 * @param props                         Props passed to the component
 * @param props.className               styled-components generated class name, needed for styling
 * @param props.dataAttachments         Currently attached files
 * @param props.dataInputFileProps      Props passed to the InputFileAttachment component
 * @param props.inputCustom             Component to use for overview in mobile view
 * @param props.isActive                Whether the component should be visible
 * @param props.linkToggleProps         Props for the toggle link
 * @param props.requestAttachmentClose  Callback to be invoked when a label is a/detached
 * @param props.requestAttachmentRemove Callback which removes attachment file
 * @returns                             The component itself
 */
const MetaAttachment: FC<Props> = ({
  className,
  dataAttachments,
  dataInputFileProps,
  inputCustom,
  isActive,
  linkToggleProps = null,
  requestAttachmentClose,
  requestAttachmentRemove,
}) => {
  const isReactNativeAndroid = getIsReactNativeAndroid();

  /** placeholder function */
  const fileInput = () => {
    dataInputFileProps.ref.current?.click();
  };

  const idForm = useId();
  const idHeading = useId();

  // Popup top buttons' labels
  const labelAction = translate('GENERAL__ADD');
  const labelConfirm = translate('GENERAL__SAVE');
  const labelClose = translate('GENERAL__CLOSE');

  const textPlaceholder = translate('ATTACHMENT__EDIT__PLACEHOLDER');
  const textHeading = textPlaceholder;

  // Props used for both inline and popup meta
  const propsCommon: PropsInline | PropsPopup = {
    action: {
      label: labelAction,
      onClick: fileInput,
    },
    className,
    idForm,
    idHeading,
    isSaveDisabled: true,
    labelClose,
    labelConfirm,
    onClose: requestAttachmentClose,
    shouldHideSave: true,
    textHeading,
  };

  const children = (
    <>
      <AttachmentList
        attachments={dataAttachments}
        mode="detailsEdit"
        requestFileRemove={requestAttachmentRemove}
      />
      <InputFileAttachment inputFileProps={dataInputFileProps} />
    </>
  );

  /**
   * Only show permission modal on Android when this component is visible.
   */
  useEffect(() => {
    if (isActive && isReactNativeAndroid) {
      postPermissionCameraEvent();
    }
  }, [isActive, isReactNativeAndroid]);

  if (linkToggleProps === null) {
    return isActive ? (
      <MetaContainerInline {...propsCommon}>{children}</MetaContainerInline>
    ) : null;
  }

  return (
    <MetaContainerPopup
      {...propsCommon}
      inputCustom={inputCustom}
      linkProps={linkToggleProps}
      placeholder={textPlaceholder}
      shouldShowPopup={isActive}
      value={intlPluralFormatter(dataAttachments.map(({ name }) => name))}
    >
      {children}
    </MetaContainerPopup>
  );
};

export default MetaAttachment;
