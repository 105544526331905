import { styled } from 'styled-components';

import devices from '../../styles/devices';
import { getQueryByDevice } from '../../styles/getQuery';

type InnerWrapperProps = {
  'data-should-show': boolean;
};

/**
 * Get media query to use for the wrapper
 *
 * @returns The query to use
 */
const getQuery = () => getQueryByDevice(null, 'tabletS');

export const Wrapper = styled.section`
  @media ${devices.tabletS} {
    padding: var(--spacing-medium);
  }

  @media ${getQuery} {
    height: 100%;
  }
`;

export const InnerWrapper = styled.div`
  background-color: var(--color-background);
  border-radius: var(--border-radius-tiny);

  /* Toggle positions relative to this */
  position: relative;

  /* When not visible, we want to the edge of the screen */

  @media ${getQuery} {
    display: flex;
    flex-flow: column;
    height: 100%;
    margin: 0;
    padding: 0;

    /* So that the compose form goes over the floating button */
    z-index: var(--z-compose-main);
  }
`;

export const Content = styled.div<InnerWrapperProps>`
  padding: var(--spacing-tiny);
  padding-top: var(--spacing-small);

  @media ${getQuery} {
    box-shadow: none;
    flex-grow: 1;
    overflow-y: auto;
    padding: 0;
  }

  @media ${devices.laptopS} {
    border: solid var(--color-border-dark);
    border-radius: inherit;
    border-width: ${props => (props['data-should-show'] ? 1 : 0)}px;
    box-shadow: var(--elevation-2);
    padding: var(--spacing-medium);
  }
`;
