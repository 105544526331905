import React, { FC } from 'react';

import { AttachmentMode } from '../../../models/attachment';
import Spinner from '../../Common/Spinner';

import * as Styled from './styled';

const ICON_SIZE = 10;
const SPINNER_SIZE = 18;

export type Props = {
  isUploading?: boolean;
  mode: AttachmentMode;
  requestButtonClick: () => void;
};

/**
 * Fragment of AttachmentSingle rendered in compose mode
 *
 * @param props                    Props passed to the component
 * @param props.isUploading        Whether the attachment is being uploaded
 * @param props.mode               In which mode to render the component
 * @param props.requestButtonClick Callback for delete button click
 * @returns                        The component itself
 */
const ComposeFragment: FC<Props> = ({
  isUploading = false,
  mode,
  requestButtonClick,
}) => {
  if (mode !== 'compose') {
    return null;
  }

  if (isUploading) {
    return <Spinner size={SPINNER_SIZE} />;
  }

  return (
    <Styled.ButtonClose
      iconColor="inherit"
      iconSize={ICON_SIZE}
      onClick={requestButtonClick}
    />
  );
};

export default ComposeFragment;
