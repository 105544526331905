import React, { FC } from 'react';

import { IconPropsExtended } from '../types';

import MaterialSymbolIconBase from './MaterialSymbolIconBase';

/**
 * Material symbol info icon
 *
 * @param props Props passed to the component
 * @returns     Info icon
 */
const MaterialSymbolInfoIcon: FC<IconPropsExtended> = props => (
  <MaterialSymbolIconBase {...props} iconName="info" />
);

export default MaterialSymbolInfoIcon;
