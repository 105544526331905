import React, { FC } from 'react';

import { IconPropsExtended } from '../types';

import MaterialSymbolIconBase from './MaterialSymbolIconBase';

/**
 * Material symbol warning icon
 *
 * @param props Props passed to the component
 * @returns     Warning icon
 */
const MaterialSymbolWarningIcon: FC<IconPropsExtended> = props => (
  <MaterialSymbolIconBase {...props} iconName="warning" />
);

export default MaterialSymbolWarningIcon;
