import React, { FC } from 'react';

import translate from '../../../i18n/translate';

import * as Styled from './styled';

export type Props = {
  className?: HTMLButtonElement['className'];
  onClick?: () => void;
};

const ICON_SIZE = 20;

/**
 * Button wrapper that returns reused Button and Icon components combined with string Archive,
 * with onClick callback
 *
 * @param props           The props passed to the component
 * @param props.className styled-components generated class name
 * @param props.onClick   Button click callback, can be omitted when type is submit
 * @returns               The component itself
 */
const ButtonArchive: FC<Props> = ({ onClick, className }) => {
  const buttonLabel = translate('GENERAL__ARCHIVE');

  return (
    <Styled.ButtonIconWithText
      aria-haspopup="true"
      className={className}
      color="var(--color-primary-lighter)"
      colorHover="var(--color-hover-dark)"
      iconColor="primary"
      iconName="archive"
      iconPosition="right"
      iconWidth={ICON_SIZE}
      label={buttonLabel}
      onClick={onClick}
      text={buttonLabel}
    />
  );
};

export default ButtonArchive;
