/**
 * @file contains a custom react hook for adding toast message
 */

import React, { useCallback } from 'react';

import { toast, ToastContentProps } from 'react-toastify';

import ToastMessage from '../components/Common/ToastMessage';
import { ToastMessageModelWithType } from '../models/toastMessage';
import { getToastAutoClose } from '../utils/toast';

/**
 *
 * Custom hook for triggering a toast message
 *
 * @returns Callback which triggers a toast message
 */
const useToast = () => {
  /**
   * Show toast message
   *
   * @param toastMessage Toast message to show
   */
  const pushToast = useCallback<
    (toastMessage: ToastMessageModelWithType) => void
  >(toastMessage => {
    const { customAction, details, duration, shouldShowClose, text, type } =
      toastMessage;
    const data = { customAction, details, shouldShowClose, text };

    toast(
      (props: ToastContentProps) => <ToastMessage {...props} data={data} />,
      { autoClose: getToastAutoClose(duration), pauseOnHover: false, type },
    );
  }, []);

  return pushToast;
};

export default useToast;
