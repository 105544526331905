import { styled } from 'styled-components';

import { visuallyHidden } from '../../styles/common';
import ButtonIcon from '../Common/ButtonIcon';

export const HiddenLink = styled.a`
  ${visuallyHidden}
`;

export const Button = styled(ButtonIcon)`
  /* stylelint-disable-next-line selector-class-pattern */
  .MuiSvgIcon-root {
    &:hover {
      color: var(--color-hover-dark);
    }
  }
`;
