import React, { FC } from 'react';

import { IconPropsExtended } from '../types';

import MaterialSymbolIconBase from './MaterialSymbolIconBase';

/**
 * Material symbol clock outlined icon
 *
 * @param props Props passed to the component
 * @returns     Clock icon
 */
const MaterialSymbolClockIcon: FC<IconPropsExtended> = props => (
  <MaterialSymbolIconBase {...props} iconName="clock" />
);

export default MaterialSymbolClockIcon;
