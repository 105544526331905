import { Typography } from '@mui/material';
import React, { FC } from 'react';

import { ToastMessageModelWithType } from '../../../models/toastMessage';

import { getToastIcon } from './helpers';
import * as Styled from './styled';

type Props = Pick<ToastMessageModelWithType, 'text' | 'type'>;

/**
 * Header for the toast message
 *
 * @param props      The props passed to the component
 * @param props.text The text to show
 * @param props.type The type of the toast message
 * @returns          The component itself
 */
const ToastHeader: FC<Props> = ({ text, type }) => {
  const iconName = getToastIcon(type);

  return (
    <Styled.ToastCardContent>
      <Styled.Icon data-message-type={type} icon={iconName} />
      <Typography>{text}</Typography>
    </Styled.ToastCardContent>
  );
};

export default ToastHeader;
