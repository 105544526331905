/**
 * @file Matomo helpers
 */

import { Message } from '../../generated/graphql';
import {
  getIsCollectiveConcierge,
  getIsConcierge,
  getIsRecurring,
  getIsRequest,
} from '../../utils/message';

/**
 * Get post origin custom dimension for matomo tracking
 *
 * @param message Message that is being tracked
 * @returns       Post origin custom dimension
 */
export const getMessageOrigin = (
  message: Pick<Message, 'creatorType' | 'type'>,
): string => {
  if (getIsCollectiveConcierge(message.type)) {
    return 'collective concierge post';
  }

  if (getIsConcierge(message.type)) {
    return 'concierge post';
  }

  if (getIsRequest(message)) {
    return 'request post';
  }

  if (getIsRecurring(message.creatorType)) {
    return 'recurring post';
  }

  return 'regular post';
};
