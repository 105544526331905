import React, { FC } from 'react';

import FieldIcon from '../FieldIcon';

import { Props } from '../props';

import * as Styled from './styled';

/**
 * The radio button field has a radio icon in front of the textual value
 *
 * @param props             Props passed to the component
 * @param props.field       The field to render
 * @param props.field.value Concierge field value
 * @returns                 The component itself
 */
const FieldRadio: FC<Props> = ({ field: { value } }) => (
  <>
    <Styled.Dd>
      <FieldIcon iconName="logout" text={value} />
    </Styled.Dd>
  </>
);

export default FieldRadio;
