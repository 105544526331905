import React, { FC, useLayoutEffect, useState } from 'react';

import useAttachmentUpload from '../../../hooks/attachments/useAttachmentUpload';

import useIsMobile from '../../../hooks/useIsMobile';

import {
  Attachment,
  AttachmentDraft,
  AttachmentMode,
} from '../../../models/attachment';
import { TypeMessageFull } from '../../../models/message';
import { TranslationModel } from '../../../models/translations';
import {
  getIsCollectiveConcierge,
  getIsConcierge,
  getIsCreatorActive,
} from '../../../utils/message';
import AttachmentList from '../../Attachments/AttachmentList';
import { getCombinedAttachments } from '../../Message/helpers/attachments';

import MessageBody from './MessageBody';
import MessageLabels from './MessageLabels';
import MessageTitle from './MessageTitle';

import * as Styled from './styled';

export type Props = {
  message: TypeMessageFull;
  mode: AttachmentMode;
  shouldClamp: boolean;
  shouldRenderLinks: boolean;
  translation?: TranslationModel;
};

/**
 * Show a single message in the feed
 *
 * @param props                   Props passed to the component
 * @param props.message           The message to show
 * @param props.mode              Mode to render Attachment components in
 * @param props.shouldClamp       Whether to clamp message text after x lines
 * @param props.shouldRenderLinks Whether to render links as <a />
 * @param props.translation       Translations for the message
 * @returns                       The component itself
 */
export const MessageContent: FC<Props> = ({
  message,
  mode,
  shouldClamp,
  shouldRenderLinks,
  translation,
}) => {
  const [attachmentsCombined, setAttachmentsCombined] = useState<
    (Attachment | AttachmentDraft)[]
  >([]);

  const { attachmentsDraft } = useAttachmentUpload(
    'EDIT' + message.id,
    message.id,
    attachmentsCombined.length,
  );

  useLayoutEffect(() => {
    if (message?.files) {
      setAttachmentsCombined(
        getCombinedAttachments(message.files, attachmentsDraft),
      );
    }
  }, [attachmentsDraft, message?.files]);

  const {
    assignedGroups,
    assignedMembers,
    checklist,
    dueDate,
    id,
    status,
    thread,
    type,
  } = message;

  const isMobile = useIsMobile();

  const creatorNotActive = !getIsCreatorActive(message);
  const title = translation?.title ?? message.title;
  const text = translation?.text ?? message.text;
  const shouldShowAttachments = mode !== 'details';
  const isFullWidth =
    getIsConcierge(type) || getIsCollectiveConcierge(type) || isMobile;

  return (
    <Styled.Content data-is-full-width={isFullWidth}>
      {isMobile && (
        <MessageTitle isDimmed={creatorNotActive}>{title}</MessageTitle>
      )}
      <Styled.MessageInfoWrapper
        assignedGroups={assignedGroups}
        assignedMembers={assignedMembers}
        checklist={checklist}
        dueDate={dueDate}
        id={id}
        shouldRenderLinks={shouldRenderLinks}
        status={status}
      />
      <MessageLabels labels={message.labels} />

      <MessageBody
        isDeactivated={creatorNotActive}
        shouldClamp={shouldClamp}
        shouldRenderLinks={shouldRenderLinks}
        thread={thread}
        type={type}
      >
        {text}
      </MessageBody>

      {shouldShowAttachments && (
        <AttachmentList
          attachments={attachmentsCombined}
          message={message}
          mode={mode}
        />
      )}
    </Styled.Content>
  );
};
