import { styled } from 'styled-components';

import { borderFocusStyle } from '../../../styles/common';

import { getQueryByDevice } from '../../../styles/getQuery';

type SelectLinkProps = {
  'data-active': boolean;
};

const mediaQuery = getQueryByDevice(null, 'tabletS');

/**
 * Get which border to use for the element
 *
 * @param props Props passed to SelectLink
 * @returns     String containing the variable to use
 */
const getBorder = (props: SelectLinkProps): string => {
  if (props['data-active']) {
    return 'var(--border-outline)';
  }

  return 'var(--border-dark)';
};

/**
 * Wrapper around the "input", which is actually a link
 * That way, we get deep linking support for toggling these
 */
export const Wrapper = styled.div<SelectLinkProps>`
  align-items: center;
  background-color: var(--color-background);
  border: ${getBorder};
  border-radius: var(--border-radius-tiny);
  display: flex;
  height: 100%;
  ${borderFocusStyle};
  outline: 0;
  padding: var(--spacing-tiny) var(--spacing-small);
  width: 100%;

  @media ${mediaQuery} {
    border-left: none;
    border-radius: 0;
    border-right: none;
    border-top: none;
    justify-content: space-between;
    min-width: auto;
    padding: 0;
  }
`;
