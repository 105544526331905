import { styled } from 'styled-components';

import { visuallyHidden } from '../../../../styles/common';

// A hidden checkbox reflecting the state, so we get keyboard accessibility
export const Toggle = styled.input`
  ${visuallyHidden};
`;

// Wrapper around the entire component
export const Wrapper = styled.div`
  align-items: center;
  background-color: var(--color-primary-lighter);
  border-top-left-radius: var(--border-radius-tiny);
  border-top-right-radius: var(--border-radius-tiny);
  bottom: 100%;
  box-shadow: var(--elevation-5);

  /**
   * Cut box-shadow off below the toggle, but leave it on the sides
   * Only url() is unsupported, and we don't use that
   */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  clip-path: inset(-5px -5px 0 -5px);
  color: var(--color-text-alt);
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  transition: background-color var(--preference-transition-duration-normal);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  width: min-content;

  &:hover,
  ${Toggle}:focus-visible + & {
    background-color: var(--color-primary-light);
  }

  /* Set explicit height on the closed toggle */
  ${Toggle}:not(:checked) + & {
    height: var(--preference-compose-toggle-closed-height);
    padding: var(--spacing-medium);
  }

  ${Toggle}:checked + & {
    padding: var(--spacing-tiny);
    width: var(--spacing-huge);
  }
`;

// "Create a Post" text
export const Title = styled.h5`
  flex-grow: 1;
  font-size: var(--font-lg);
  font-weight: 600;
  margin: 0 var(--spacing-medium);
  white-space: nowrap;
`;
