import { Link as RouterLink } from 'react-router-dom';
import { styled } from 'styled-components';

import { CssVariable } from '../../../../styles/cssTypes';

import devices from '../../../../styles/devices';

const breakpoint = devices.tabletS;
const labelSize = '23px';

export const Li = styled.li`
  border: 1px solid transparent; /* for accessibility outline */
`;

type LinkProps = {
  'data-is-selected': boolean;
};

/**
 * Get which color to use for the link background
 *
 * @param props Props passed to the link
 * @returns     The variable to use
 */
const getLinkBackgroundColor = (props: LinkProps): CssVariable => {
  return props['data-is-selected']
    ? 'var(--color-background-selected)'
    : 'transparent';
};

/**
 * Get which color to use for the label background
 *
 * @param props Props passed to the link
 * @returns     The variable to use
 */
const getLabelBackground = (props: ColorLabelProps): CssVariable => {
  return props['data-is-selected']
    ? 'var(--color-primary-light)'
    : 'var(--color-background)';
};

export const Link = styled(RouterLink)<LinkProps>`
  align-items: center;
  border-radius: var(--border-radius-tiny);
  display: flex;
  font-weight: ${props =>
    props['data-is-selected'] ? 'var(--font-semibold)' : 'var(--font-light)'};
  justify-content: space-between;
  padding: var(--spacing-small);

  @media ${breakpoint} {
    background-color: ${getLinkBackgroundColor};
    font-weight: ${props =>
      props['data-is-selected'] ? 'var(--font-semibold)' : 'var(--font-light)'};
    transition: background-color var(--preference-transition-duration-normal);

    &:hover {
      background-color: var(--color-hover-light);
    }
  }
`;

export const LabelWrapper = styled.span`
  display: flex;
`;

type ColorLabelProps = {
  'data-is-selected': boolean;
};

export const ColorLabel = styled.div<ColorLabelProps>`
  background-color: ${getLabelBackground};
  border: 1px solid var(--color-primary-light);
  border-radius: var(--border-radius-tiny);
  flex-shrink: 0; /* this is needed to keep width of the labels full */
  height: ${labelSize};
  margin-right: var(--spacing-small);
  width: ${labelSize};
`;
