import React from 'react';

import SettingsAutomaticTranslationContainer from '../../../../containers/settings/AutomaticTranslation';

/**
 * Automatic translations settings page
 *
 * @returns The component itself
 */
const SettingsAutomaticTranslation = () => {
  return (
    <>
      <SettingsAutomaticTranslationContainer />
    </>
  );
};

export default SettingsAutomaticTranslation;
