import React, { FC } from 'react';

import { IconPropsExtended } from '../types';

import MaterialSymbolIconBase from './MaterialSymbolIconBase';

/**
 * Material symbol clock filled icon
 *
 * @param props Props passed to the component
 * @returns     ClockFilled icon
 */
const MaterialSymbolClockFilledIcon: FC<IconPropsExtended> = props => (
  <MaterialSymbolIconBase {...props} iconName="clockFilled" />
);

export default MaterialSymbolClockFilledIcon;
