import { styled } from 'styled-components';

import { visuallyHidden } from '../../../styles/common';

// Group name
export const Heading = styled.p`
  background-color: var(--color-background-tools);
  border: var(--border-group);
  border-radius: var(--border-radius-tiny);
  color: var(--color-text-light);
  cursor: pointer;
  display: inline-block;
  margin-bottom: var(--spacing-small);
  overflow-wrap: anywhere;
  padding: var(--spacing-small) var(--spacing-medium);
  transition:
    background-color var(--preference-transition-duration-normal),
    color var(--preference-transition-duration-normal);

  @media (hover: hover) {
    &:hover {
      border: var(--border-outline);
    }
  }
`;

// Group toggle (invisible but focusable)
export const Input = styled.input`
  ${visuallyHidden}

  &:focus-visible + ${Heading} {
    border: var(--border-outline);
  }

  &:checked + ${Heading} {
    background-color: var(--color-primary-light);
    color: var(--color-text-alt);
  }
`;
