/**
 * @file contains app's redux initial state
 */

import { Frequency } from 'rrule';

import {
  PREFERENCE_MESSAGE_READ_DEBOUNCE,
  PREFERENCE_MESSAGE_READ_DELAY,
} from '../constants/preferences';
import { DebugState } from '../models/debug';
import { DraftsState } from '../models/draft';
import { DraftAttachmentState } from '../models/draftAttachment';
import { MarkAsReadState } from '../models/markAsRead';
import { NativeAppState } from '../models/nativeApp';
import { RecurringState } from '../models/recurring';
import { ScrollState } from '../models/scroll';
import { SubscriptionsState } from '../models/subscriptions';
import { TranslationsState } from '../models/translations';
import { getOneDayAfterDate } from '../utils/date/getOneDayAfterDate';
import { getOneHourAfterDate } from '../utils/date/getOneHourAfterDate';
import { getWeekday } from '../utils/rrule/getWeekday';

export type InitialState = {
  debug: DebugState;
  drafts: DraftsState;
  draftsAttachments: DraftAttachmentState;
  draftRecurring: RecurringState;
  markAsRead: MarkAsReadState;
  nativeApp: NativeAppState;
  scroll: ScrollState;
  subscriptions: SubscriptionsState;
  translations: TranslationsState;
};

/**
 * Initial state for the entire app
 */
const initialState: InitialState = {
  debug: {
    hasMessages: null,
    networkComments: false,
    networkCompose: false,
    networkMessageDetails: false,
    networkMessages: null,
    networkQuickFilters: false,
    networkTopicBasic: false,
    networkTopicDetails: false,
    networkTopics: false,
    panelState: false,
  },
  draftRecurring: {
    bymonthdayCurrent: new Set(),
    bymonthdaySaved: new Set(),
    bysetpos: 1,
    byweekday: new Set([getWeekday()]),
    byweekdaySingle: 0,
    dtstartCurrent: getOneHourAfterDate(),
    dtstartSaved: getOneHourAfterDate(),
    frequency: Frequency.DAILY,
    intervalSaved: 1,
    templateId: null,
    untilCurrent: getOneDayAfterDate(),
    untilSaved: getOneDayAfterDate(),
  },
  drafts: new Map(),
  draftsAttachments: new Map(),
  markAsRead: {
    debounce: PREFERENCE_MESSAGE_READ_DEBOUNCE,
    delay: PREFERENCE_MESSAGE_READ_DELAY,
  },
  nativeApp: {
    deviceInfo: null,
    downloadInfo: null,
    firebaseInfo: null,
  },
  scroll: {
    feed: {},
    sidebar: 0,
    topicsLatestPost: new Set(),
  },
  subscriptions: {
    messageIds: [],
    topicIds: [],
  },
  translations: {
    messages: new Map(),
  },
};

export default initialState;
