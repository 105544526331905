import { keyframes, styled } from 'styled-components';

type LineProps = {
  'data-color'?: string;
  'data-width'?: number;
};

/**
 * Moving the "shine" over the skeleton icon
 * -20% and 120% because its width is 20%
 */
const animation = keyframes`
  from {
    background-position-x: -20%;
  }

  to {
    background-position-x: 120%;
  }
`;

/**
 * Get the variable to be used for "shine" color
 *
 * @param props Props passed to the line component
 * @returns     The variable to use
 */
const getAnimationColor = (props: LineProps): string => {
  if (props['data-color'] === undefined) {
    return '--color-background';
  }

  return '--color-background-skeleton';
};

/**
 * Get the variable to be used for the line background color
 *
 * @param props Props passed to the line component
 * @returns     The variable to use
 */
const getBackgroundColor = (props: LineProps): string => {
  return props['data-color'] ?? '--color-background-skeleton';
};

// The line itself
export const Line = styled.div<LineProps>`
  animation: ${animation} var(--preference-transition-duration-long) linear
    infinite;
  background-color: var(${getBackgroundColor});

  /* Safari doesn't support "transparent" keyword, so using rgba equivalent here */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  background-image: linear-gradient(
    115deg,
    var(--color-transparent),
    var(${getAnimationColor}),
    var(--color-transparent)
  );
  background-repeat: no-repeat;
  background-size: 20% 100%;
  border-radius: var(--border-radius-tiny);
  height: 100%;
  width: ${props => props['data-width'] ?? 100}%;
`;
