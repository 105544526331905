// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roq-chat-window__content {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.roq-chat-message-list__list {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  overscroll-behavior-y: contain;
}

.roq-chat-message-list-wrapper__spacer {
  flex-grow: 1;
}

/* Element above a single chat message, containing the date or nothing */
.roq-chat-message-list-line__before {
  position: relative;
  text-align: center;
}

/* Children who need to have height 100% so that and empty conversation message is centered */
.roq-chat-window__content__dropzone {
  height: 100%;
}

/* Wrapper of the messages list wrapper */
.roq-chat-window__content__messages {
  height: 100%;
}

/* The element that displays the date */
.roq-chat-date-seperator__time {
  align-items: center;
  background-color: var(--color-background);
  border: var(--border-dark);
  border-radius: var(--border-radius-tiny);
  font-size: var(--font-sm);
  font-weight: var(--font-light);
  padding: var(--spacing-small)
    calc(var(--spacing-medium) + var(--spacing-small));
  position: relative;
}

.roq-chat-date-seperator {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/roqChat/_window-content.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,uEAAuE;EACvE,8BAA8B;AAChC;;AAEA;EACE,YAAY;AACd;;AAEA,wEAAwE;AACxE;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA,6FAA6F;AAC7F;EACE,YAAY;AACd;;AAEA,yCAAyC;AACzC;EACE,YAAY;AACd;;AAEA,uCAAuC;AACvC;EACE,mBAAmB;EACnB,yCAAyC;EACzC,0BAA0B;EAC1B,wCAAwC;EACxC,yBAAyB;EACzB,8BAA8B;EAC9B;sDACoD;EACpD,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".roq-chat-window__content {\n  flex-grow: 1;\n  height: 100%;\n  overflow: hidden;\n  position: relative;\n}\n\n.roq-chat-message-list__list {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow-x: hidden;\n  overflow-y: auto;\n  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */\n  overscroll-behavior-y: contain;\n}\n\n.roq-chat-message-list-wrapper__spacer {\n  flex-grow: 1;\n}\n\n/* Element above a single chat message, containing the date or nothing */\n.roq-chat-message-list-line__before {\n  position: relative;\n  text-align: center;\n}\n\n/* Children who need to have height 100% so that and empty conversation message is centered */\n.roq-chat-window__content__dropzone {\n  height: 100%;\n}\n\n/* Wrapper of the messages list wrapper */\n.roq-chat-window__content__messages {\n  height: 100%;\n}\n\n/* The element that displays the date */\n.roq-chat-date-seperator__time {\n  align-items: center;\n  background-color: var(--color-background);\n  border: var(--border-dark);\n  border-radius: var(--border-radius-tiny);\n  font-size: var(--font-sm);\n  font-weight: var(--font-light);\n  padding: var(--spacing-small)\n    calc(var(--spacing-medium) + var(--spacing-small));\n  position: relative;\n}\n\n.roq-chat-date-seperator {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
