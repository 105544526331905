import React, { FC, PropsWithChildren } from 'react';

import { ToastMessageModel } from '../../../models/toastMessage';

import ToastAction from './ToastAction';
import * as Styled from './styled';

type Props = PropsWithChildren &
  Pick<ToastMessageModel, 'customAction' | 'shouldShowClose'> & {
    requestCloseToast: () => void;
  };

/**
 * Footer for the toast message
 *
 * Extracted to a separate component to avoid code duplication.
 * (The same footer is used in the toast messages that are and are not rendered inside of the React components.)
 *
 * @param props                   The props passed to the component
 * @param props.children          The children to show
 * @param props.customAction      Actions to show
 * @param props.requestCloseToast Callback to close the toast
 * @param props.shouldShowClose   Whether the close button should be shown
 * @returns                       The component itself
 */
const ToastFooter: FC<Props> = ({
  children,
  customAction,
  requestCloseToast,
  shouldShowClose,
}) => {
  return (
    <Styled.ToastCardFooter disableSpacing={true}>
      {children}
      <ToastAction
        customAction={customAction}
        requestCloseToast={requestCloseToast}
        shouldShowClose={shouldShowClose}
      />
    </Styled.ToastCardFooter>
  );
};

export default ToastFooter;
