import React, { lazy, Suspense } from 'react';
import { RouteProps } from 'react-router-dom';

import SplashScreen from '../../components/SplashScreen';
import PageSettingsAutomaticTranslation from '../pages/Settings/AutomaticTranslation';
import {
  createSettingsGroupsPath,
  createSettingsLabelsPath,
  createSettingsNotificationsEmailPath,
  createSettingsNotificationsEmailSinglePath,
  createSettingsNotificationsPath,
  createSettingsNotificationsPushPath,
  createSettingsNotificationsPushSinglePath,
  createSettingsPath,
  createSettingsPeoplePath,
  createSettingsTemplateEditPath,
  createSettingsTemplatesPath,
  createSettingsTemplatesRecurringPath,
  createSettingsTopicsPath,
  createSettingsTranslationPath,
} from '../paths/settings';

const PageSettingsHome = lazy(() => import('../pages/Settings'));
const PageSettingsGroups = lazy(() => import('../pages/Settings/Groups'));
const PageSettingsTopics = lazy(() => import('../pages/Settings/Topics'));
const PageSettingsTemplates = lazy(() => import('../pages/Settings/Templates'));
const PageSettingsTemplatesRecurring = lazy(
  () => import('../pages/Settings/Templates/Recurring'),
);
const PageSettingsLabels = lazy(() => import('../pages/Settings/Labels'));
const PageSettingsNotifications = lazy(
  () => import('../pages/Settings/Notifications'),
);
const PageSettingsPeople = lazy(() => import('../pages/Settings/People'));

const mappingRoutesSettings: RouteProps[] = [
  { element: <PageSettingsHome />, path: createSettingsPath() },
  {
    element: <PageSettingsTopics />,
    path: createSettingsTopicsPath(),
  },
  {
    element: <PageSettingsTemplates />,
    path: createSettingsTemplateEditPath(),
  },
  { element: <PageSettingsTemplates />, path: createSettingsTemplatesPath() },
  {
    element: <PageSettingsTemplatesRecurring />,
    path: createSettingsTemplatesRecurringPath(),
  },
  {
    element: <PageSettingsAutomaticTranslation />,
    path: createSettingsTranslationPath(),
  },
  { element: <PageSettingsPeople />, path: createSettingsPeoplePath() },
  { element: <PageSettingsGroups />, path: createSettingsGroupsPath() },
  { element: <PageSettingsLabels />, path: createSettingsLabelsPath() },
  {
    element: <PageSettingsNotifications />,
    path: createSettingsNotificationsPath(),
  },
  {
    element: <PageSettingsNotifications />,
    path: createSettingsNotificationsEmailPath(),
  },
  {
    element: <PageSettingsNotifications />,
    path: createSettingsNotificationsEmailSinglePath(),
  },
  {
    element: <PageSettingsNotifications />,
    path: createSettingsNotificationsPushSinglePath(),
  },
  {
    element: <PageSettingsNotifications />,
    path: createSettingsNotificationsPushPath(),
  },
];

export default mappingRoutesSettings.map(({ element, path }) => ({
  element: <Suspense fallback={<SplashScreen />}>{element}</Suspense>,
  path,
}));
