/**
 * @file Mappings between mui symbol icons and svg file names within the assets/icons
 * folder (prefixed with mui_symbol__).
 */

import { MuiSymbolIconName } from '../../types';

export const muiIconNameMapping: Record<MuiSymbolIconName, string> = {
  clock: 'mui_symbol__clock',
  clockFilled: 'mui_symbol__clock_filled',
  download: 'mui_symbol__download',
  error: 'mui_symbol__error',
  home: 'mui_symbol__home',
  info: 'mui_symbol__info',
  settings: 'mui_symbol__settings',
  warning: 'mui_symbol__warning',
};
