import { styled } from 'styled-components';

import CommonButton from '../Button';

export type ButtonProps = {
  'data-color': string;
  'data-color-hover': string;
};

/**
 * Get the color to use for button text
 *
 * @param props The props passed to the button
 * @returns     The color to use
 */
const getColorHover = (props: ButtonProps) => {
  return props['data-color-hover'];
};

/**
 * Get the color to use for button text
 *
 * @param props The props passed to the button
 * @returns     The color to use
 */
const getColorRegular = (props: ButtonProps) => {
  return props['data-color'];
};

export const Button = styled(CommonButton)<ButtonProps>`
  color: ${getColorRegular};

  /* stylelint-disable-next-line selector-class-pattern */
  & > .MuiSvgIcon-root {
    &:hover {
      color: ${getColorHover};
    }
  }
`;
