import React, { FC } from 'react';

import { formatConciergeData } from '../../../../formatters/concierge/formMessagingApi';

import { MessageType, Thread } from '../../../../generated/graphql';
import { reportError } from '../../../../services/reporting';
import MessageConcierge from '../../MessageConcierge';

import MessageText from '../MessageText';

import * as Styled from './styled';

type Props = {
  children: string;
  isDeactivated: boolean;
  shouldClamp: boolean;
  shouldRenderLinks: boolean;
  thread?: Thread | null;
  type: MessageType;
};

/**
 * Message body,
 * which is either plain text
 * or a contact form
 *
 * @param props                   Props passed to the component
 * @param props.children          Message contents
 * @param props.isDeactivated     Whether the author is deactivated
 * @param props.shouldClamp       Whether to clamp message text after x lines
 * @param props.shouldRenderLinks Whether to render links as <a />
 * @param props.thread            The thread the message belongs to (applicable to Messaging API only)
 * @param props.type              The message type, dictating what we want to show
 * @returns                       The component itself
 */
const MessageBody: FC<Props> = ({
  children,
  isDeactivated,
  shouldClamp,
  shouldRenderLinks,
  thread,
  type,
}) => {
  const textProps = {
    isDeactivated,
    shouldClamp,
    shouldRenderLinks,
  };

  if (
    type === MessageType.PLAIN_TEXT ||
    type === MessageType.COLLECTIVE_CONCIERGE_FORM
  ) {
    return <MessageText {...textProps}>{children}</MessageText>;
  }

  const data = formatConciergeData(children, thread);

  if (data === null) {
    reportError(`MessageBody: Malformed JSON in form data:\n ${children}`);
    return null;
  }

  return (
    <>
      {data.description && (
        <MessageText {...textProps}>{data.description}</MessageText>
      )}
      <Styled.Wrapper>
        <Styled.Content>
          <MessageConcierge data={data} />
        </Styled.Content>
      </Styled.Wrapper>
    </>
  );
};

export default MessageBody;
