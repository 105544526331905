import React, { FC, useLayoutEffect, useState } from 'react';

import { HASH_MESSAGE__INFO__ATTACHMENT } from '../../constants/routing/hashLinks';
import useAttachmentUpload from '../../hooks/attachments/useAttachmentUpload';

import translate from '../../i18n/translate';
import { Attachment, AttachmentDraft } from '../../models/attachment';
import { TypeMessageFull } from '../../models/message';
import MessageAttachment from '../Common/MessageAttachments';

import MessageSection from './MessageSection';
import { getCombinedAttachments } from './helpers/attachments';

type Props = {
  message: TypeMessageFull;
};

/**
 * Section in the message that displays the attachments
 *
 * @param props         Props passed to the component
 * @param props.message The message itself
 * @returns             The component itself
 */
const MessageSectionAttachments: FC<Props> = ({ message }) => {
  const [attachmentsCombined, setAttachmentsCombined] = useState<
    (Attachment | AttachmentDraft)[]
  >([]);
  const messageId = message?.id;
  const { attachmentsDraft, inputProps } = useAttachmentUpload(
    'EDIT' + messageId,
    messageId,
    attachmentsCombined.length,
  );

  useLayoutEffect(() => {
    if (message?.files) {
      setAttachmentsCombined(
        getCombinedAttachments(message.files, attachmentsDraft),
      );
    }
  }, [attachmentsDraft, message?.files]);

  return (
    <MessageSection
      heading={translate('MESSAGE__HEADING__ATTACHMENTS')}
      /**
       * Using "raw" ID constants (without useComponentId()) in order to match them
       * with ones in <HashLink> in <MessageSingle/> component. If we used useComponentId(),
       * every time we would create unique ID value and could not scroll proper element into view.
       */
      id={HASH_MESSAGE__INFO__ATTACHMENT}
    >
      <MessageAttachment
        attachments={attachmentsCombined}
        inputFileProps={inputProps}
        message={message}
      />
    </MessageSection>
  );
};

export default MessageSectionAttachments;
