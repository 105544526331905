/**
 * @file A user target language was updated, so we update the cache with it
 */
import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  UserTargetLanguageUpdateMutation,
  UserTargetLanguageUpdateMutationVariables,
} from '../../../../../generated/graphql';
import usersUpdate from '../utils/updaters/users/userTargetLanguageUpdate';

/**
 * Resolve cache after a user target language was updated
 *
 * @param result The result of the mutation (unused)
 * @param _args  Arguments of a graphQL operation (unused)
 * @param cache  URQL graphQL cache
 */
const userTargetLanguageUpdate: UpdateResolver<
  UserTargetLanguageUpdateMutation,
  UserTargetLanguageUpdateMutationVariables
> = (result, _args, cache) => {
  usersUpdate(cache, result.userTargetLanguageUpdate.targetLanguage);
};

export default userTargetLanguageUpdate;
