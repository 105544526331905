import React from 'react';

import { Navigate } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import AutomaticTranslationForm from '../../../components/Forms/AutomaticTranslationForm';

import {
  MeDocument,
  TargetLanguage,
  UserTargetLanguageUpdateDocument,
} from '../../../generated/graphql';

import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useIsTablet from '../../../hooks/useIsTablet';
import useToast from '../../../hooks/useToast';
import translate from '../../../i18n/translate';
import { getSettingsPeopleUrl } from '../../../routes/urls/settings';
import { reportApiErrors } from '../../../utils/error';
import { getCanViewUsersTable } from '../../../utils/permissions/user';

/**
 * Container for the Automatic Translation settings
 *
 * @returns The component itself
 */
const SettingsAutomaticTranslationContainer = () => {
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();
  const pushToast = useToast();

  // Check if the user has permission to view the table,
  // if yes, don't fetch the data and redirect to the table
  const canViewTable = getCanViewUsersTable();

  const [, userTargetLanguageUpdateMutation] = useMutation(
    UserTargetLanguageUpdateDocument,
  );
  const [{ data: meData }] = useQuery({
    pause: canViewTable,
    query: MeDocument,
    variables: {},
  });

  if (canViewTable) {
    return (
      <Navigate replace={true} to={getSettingsPeopleUrl(hotelId, isTablet)} />
    );
  }

  /**
   * Requests user language to be changed on the backend
   *
   * @param targetLanguage Selected language
   */
  const requestSave = (targetLanguage: TargetLanguage) => {
    if (meData === undefined) {
      return;
    }

    userTargetLanguageUpdateMutation({
      data: { targetLanguage },
    })
      .then(result => {
        if (result.error) {
          reportApiErrors(result.error);
          return;
        }

        pushToast({
          text: translate('SETTINGS__AUTOMATIC_TRANSLATIONS__SUCCESS'),
          type: 'success',
        });
      })
      .catch(reportApiErrors);
  };

  return (
    <AutomaticTranslationForm
      currentUserLanguage={meData?.me.targetLanguage}
      requestSave={requestSave}
    />
  );
};

export default SettingsAutomaticTranslationContainer;
