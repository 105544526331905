import { Button } from '@mui/material';
import React, { FC } from 'react';

import { Link, useLocation } from 'react-router-dom';

import translate from '../../../i18n/translate';
import { LabelUsage } from '../../../models/message';
import { getSearchParamLabelMode } from '../../../routes/helpers/searchParams';
import { getLabelModeUrl } from '../../../routes/urls/label';
import { getCanCreateLabel } from '../../../utils/permissions/label';

type Props = {
  where: LabelUsage;
};

/**
 * The content on the right side of the header varies between different label modes.
 * When we are in MODE_VIEW mode, we want to see a create action button, otherwise nothing
 * should be rendered
 *
 * @param props       Props passed to the component
 * @param props.where Where the component is rendered
 * @returns           The component itself
 */
const ContentRightLabel: FC<Props> = ({ where }) => {
  const location = useLocation();

  const labelMode = getSearchParamLabelMode(location, where);
  const canCreateLabel = getCanCreateLabel();

  if (
    canCreateLabel === false ||
    labelMode === 'MODE_EDIT' ||
    labelMode === 'MODE_CREATE'
  ) {
    return null;
  }

  const labelAction = translate('GENERAL__CREATE');

  return (
    <Button
      color="secondary"
      component={Link}
      to={getLabelModeUrl(location, where, 'MODE_CREATE')}
      variant="text"
    >
      {labelAction}
    </Button>
  );
};

export default ContentRightLabel;
