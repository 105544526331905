import React, { FC } from 'react';

import translate from '../../../../../i18n/translate';
import ButtonControl from '../ButtonControl';

type Props = {
  isActive: boolean;
  onClick: () => void;
};

/**
 * The button for styling the selected text, making it underlined
 *
 * @param props          The props passed to the component
 * @param props.isActive Whether the control is active
 * @param props.onClick  Button click callback
 * @returns              The component itself
 */
const ButtonStyleTextStrikethrough: FC<Props> = props => {
  const label = translate('COMPOSE__STYLE__STRIKETHROUGH');
  return <ButtonControl iconName="strikethrough" label={label} {...props} />;
};

export default ButtonStyleTextStrikethrough;
