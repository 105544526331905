import { Button, ButtonProps, Typography } from '@mui/material';
import React, { ComponentProps, FC, ReactNode } from 'react';

import { Link } from 'react-router-dom';

import HeaderSegmentedButtons from '../SegmentedButtons';

import { ActionButtonRightProps } from './props';
import * as Styled from './styled';

export type BaseHeaderProps = {
  actionButtonRight: ActionButtonRightProps | null;
  buttons: ButtonProps[];
  children?: ReactNode;
  description: string | null;
  leadingVisual: ReactNode;
  segmentedButtons?: ComponentProps<typeof HeaderSegmentedButtons>['buttons'];
  subtitle: string | null;
  title: string | null;
};
/**
 * A generic component that represents the header
 *
 * @param props                   Props passed to the component
 * @param props.actionButtonRight Icon config to be displayed on the right side of the header
 * @param props.buttons           Props for the buttons at the bottom of the header
 * @param props.children          Children to be displayed in the header
 * @param props.description       Description to be displayed in the header
 * @param props.leadingVisual     Icon or content shown on the left side of the header
 * @param props.segmentedButtons  Segmented buttons to be displayed in the header to navigate between different views
 * @param props.subtitle          Subtitle to be displayed in the header
 * @param props.title             Title to be displayed in the header
 * @returns                       The component itself
 */
const BaseHeaderDesktop: FC<BaseHeaderProps> = ({
  actionButtonRight,
  buttons,
  children,
  description,
  leadingVisual,
  segmentedButtons = [],
  subtitle,
  title,
}) => {
  return (
    <Styled.Wrapper>
      {leadingVisual}
      <Styled.HeaderContent>
        <Styled.HGroup>
          {title && <Typography variant="h2">{title}</Typography>}
          {subtitle !== null && (
            <Typography variant="body1">{subtitle}</Typography>
          )}
          {description && (
            <Styled.Description>{description}</Styled.Description>
          )}
        </Styled.HGroup>
        {actionButtonRight !== null && (
          <Styled.ActionButtonRight
            aria-label={actionButtonRight.label}
            component={Link}
            size="large"
            to={actionButtonRight.url}
          >
            {actionButtonRight.icon}
          </Styled.ActionButtonRight>
        )}

        <Styled.SegmentedButtons buttons={segmentedButtons} />
        {children}
        {buttons.length > 0 && (
          <Styled.ButtonsWrapper>
            {buttons.map(buttonProps => (
              <Button
                key={buttonProps.key}
                {...buttonProps}
                variant="contained"
              />
            ))}
          </Styled.ButtonsWrapper>
        )}
      </Styled.HeaderContent>
    </Styled.Wrapper>
  );
};

export default BaseHeaderDesktop;
