/**
 * @file contains utility functions for toast messages
 */
import { TOAST__MESSAGE_DURATION } from '../constants/preferences';

/**
 * Get toast duration
 *
 * @param duration Duration of the toast message
 * @returns        Duration of the toast message
 */
export const getToastAutoClose = (
  duration: number = TOAST__MESSAGE_DURATION,
) => {
  if (duration === 0) {
    return false;
  }

  return duration;
};
