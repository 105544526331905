import { SvgIconOwnProps, SvgIconProps } from '@mui/material';
import React, { FC, MouseEvent } from 'react';

import useFocus from '../../../hooks/useFocus';

import MuiIcon from '../../MUI/MuiIcon';
import { MuiIconName, MuiSymbolIconName } from '../../MUI/MuiIcon/types';
import { NAMES } from '../Icon';
import Spinner from '../Spinner';

import * as Styled from './styled';

export type Props = {
  className?: HTMLButtonElement['className'];
  color?: string;
  colorFocus?: string;
  colorHover?: string;
  fontSize?: SvgIconProps['fontSize'];
  iconColor?: SvgIconOwnProps['color'];
  iconHeight?: number;
  iconName: MuiIconName | MuiSymbolIconName;
  iconWidth?: number;
  isDisabled?: HTMLButtonElement['disabled'];
  isLoading?: boolean;
  label: NonNullable<HTMLButtonElement['ariaLabel']>;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit';
};

export const defaultColor = 'currentColor';
export const defaultType = 'button';

/**
 * Button which doesn't have any other content apart from the icon within it
 *
 * @param props            props passed to the component
 * @param props.className  styled-components generated class name, needed for styling
 * @param props.color      color of the button
 * @param props.colorFocus color of the icon when the button is focused
 * @param props.colorHover color of the icon when the button is hovered
 * @param props.fontSize   Size prop defined in Mui api, can be - SvgIconProps['fontSize']: 'inherit' | 'large' | 'small'
 * @param props.iconColor  Color of the icon
 * @param props.iconHeight the height of the included icon (button itself will be the same size)
 * @param props.iconName   which icon to show within the button
 * @param props.iconWidth  the width of the included icon (button itself will be the same size)
 * @param props.isDisabled Whether to make the button disabled
 * @param props.isLoading  Whether to show loading spinner
 * @param props.label      aria label for the button (since there's no text, just the icon)
 * @param props.onClick    click callback, not needed for submit buttons
 * @param props.type       <button> type attribute
 * @returns                the component itself
 */
const ButtonIcon: FC<Props> = ({
  className,
  color = defaultColor,
  colorHover = color,
  fontSize,
  iconHeight,
  iconColor = 'action',
  iconName,
  iconWidth,
  isDisabled = false,
  isLoading = false,
  label,
  onClick,
  type = defaultType,
  ...rest
}) => {
  const [, bind] = useFocus();

  /**
   * Moved the icon rendering logic into a separate function
   * to reduce the complexity of the main component as per ESLint rules.
   *
   * @returns The icon component (either Spinner or MuiIcon)
   */
  const renderIcon = () => {
    if (isLoading) {
      return <Spinner size={iconWidth} />;
    }

    return (
      <MuiIcon
        color={iconColor}
        fontSize={fontSize}
        height={iconHeight}
        icon={iconName}
      />
    );
  };

  return (
    <Styled.Button
      aria-label={label}
      className={className}
      data-color={color}
      data-color-hover={colorHover}
      isDisabled={isDisabled}
      label={label}
      onClick={onClick}
      type={type}
      {...rest}
      {...bind}
    >
      {renderIcon()}
    </Styled.Button>
  );
};

export { NAMES };
export default ButtonIcon;
