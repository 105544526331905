import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { UseMessagesReadMarker } from '../../../hooks/message/useMessagesRead/types';
import useAreRepliesOpen from '../../../hooks/router/search/useAreRepliesOpen';
import useTranslation from '../../../hooks/store/useTranslation';
import useIsMobile from '../../../hooks/useIsMobile';
import { MessageFeed } from '../../../models/message';
import { getCanCreateComment } from '../../../utils/permissions/comment';

import MessageDateHeading from '../MessagesDateHeading';

import * as Styled from './styled';

export type Props = {
  dateMessage: Date;
  message: MessageFeed;
  marker: UseMessagesReadMarker;
  messageDetailsUrl: string;
  requestLoadMoreAfter: (() => void) | null;
  requestLoadMoreBefore: (() => void) | null;
  shouldFocus: boolean;
  shouldRenderLinks: boolean;
  shouldShowDateHeading: boolean;
};

/**
 * Message List element that contains a message in the feed
 *
 * @param props                       Props passed to the component
 * @param props.dateMessage           Creation date of the message
 * @param props.marker                Reducer to mark a message as read
 * @param props.message               The message to show
 * @param props.messageDetailsUrl     URL leading to message-details page
 * @param props.requestLoadMoreAfter  Request callback that loads more messages
 * @param props.requestLoadMoreBefore Request callback that loads more messages
 * @param props.shouldFocus           If the message should be focused (in order to scroll to it)
 * @param props.shouldRenderLinks     Whether to render links as <a />
 * @param props.shouldShowDateHeading If date heading should be shown
 * @returns                           The component itself
 */
const MessagesListElement: FC<Props> = ({
  dateMessage,
  marker,
  message,
  messageDetailsUrl,
  requestLoadMoreAfter,
  requestLoadMoreBefore,
  shouldFocus,
  shouldRenderLinks,
  shouldShowDateHeading,
}) => {
  const isMobile = useIsMobile();
  const ref = useRef<HTMLLIElement | null>(null);
  const [inViewRef, isInView] = useInView({
    /**
     * Threshold indicates how many % of the element should be visible in order to
     * consider it as "inView". Values go from 0 to 1. Default value is 0.
     */
    threshold: 0.7,
  });

  const hasComments = message.numberOfComments > 0;
  const shouldShowComments =
    getCanCreateComment(message.isArchived) || hasComments;
  /**
   * Scroll into view the message when it is expanded.
   *
   * @param behavior How the scroll view behaves
   * @param block    How the scroll view will be positioned
   */
  const scrollIntoView = useCallback(
    (
      behavior: 'smooth' | 'auto' | undefined = 'auto',
      block: 'center' | 'end' | 'nearest' | 'start' | undefined = 'center',
    ) => {
      ref.current?.scrollIntoView({
        behavior,
        block,
      });
    },
    [],
  );

  /**
   * Set refs callback that allows us to use Intersection Observer along with
   * normal ref functionality like accessing the `current` property
   *
   * @param node DOM node in question
   */
  const setRefs = useCallback(
    (node: HTMLLIElement | null) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  useEffect(() => {
    /**
     * The second to last message is in view,
     * so we load the next page of messages
     */
    if (requestLoadMoreAfter === null) {
      return;
    }

    if (isInView) {
      requestLoadMoreAfter();
    }
    /** @todo !!! FIX MAXIMUM DEPTH */
  }, [requestLoadMoreAfter, isInView]);

  useEffect(() => {
    /**
     * The second to last message is in view,
     * so we load the next page of messages
     */
    if (requestLoadMoreBefore === null) {
      return;
    }

    if (isInView) {
      requestLoadMoreBefore();
    }
  }, [requestLoadMoreBefore, isInView]);

  useEffect(() => {
    /**
     * We came back from the search so we scroll the message
     * that is supposed to be in focus into view
     */
    const shouldScrollIntoView = shouldFocus && isInView === false;

    if (shouldScrollIntoView) {
      scrollIntoView();
    }
    /**
     * We only want to call this when shouldFocus changes
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFocus]);

  const isExpanded = useAreRepliesOpen(message.id);

  const translation = useTranslation(message.id);

  /**
   * Add message to read queue
   */
  marker({
    hasComments,
    isExpanded: isMobile ? false : isExpanded,
    isInView,
    isUnread: message.isSeen === false,
    messageId: message.id,
  });

  return (
    <Styled.Wrapper
      data-should-hide-border-bottom={shouldShowDateHeading}
      ref={setRefs}
    >
      {isMobile ? (
        <Styled.MessageSingle
          data-is-seen={message.isSeen}
          isExpanded={false}
          message={message}
          mode="feed"
          scrollIntoViewCallback={scrollIntoView}
          shouldClamp={true}
          shouldFocus={shouldFocus}
          shouldHideBorderBottom={shouldShowDateHeading}
          shouldRenderLinks={shouldRenderLinks}
          shouldShowComments={shouldShowComments}
          translation={translation}
          url={messageDetailsUrl}
        />
      ) : (
        <Styled.MessageSingle
          data-is-seen={message.isSeen}
          isExpanded={isExpanded}
          message={message}
          mode="feed"
          scrollIntoViewCallback={scrollIntoView}
          shouldFocus={shouldFocus}
          shouldHideBorderBottom={shouldShowDateHeading}
          shouldRenderLinks={shouldRenderLinks}
          shouldShowComments={shouldShowComments}
          shouldShowRibbon={true}
          translation={translation}
        />
      )}
      {shouldShowDateHeading && <MessageDateHeading date={dateMessage} />}
    </Styled.Wrapper>
  );
};

export default MessagesListElement;
