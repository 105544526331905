import React, { FC } from 'react';

import * as conciergeFieldTypes from '../../../constants/conciergeFieldTypes';

import { ContactFormDataFormatted } from '../../../models/message';

import FieldCheckbox from './FieldValue/FieldCheckbox';
import FieldDescription from './FieldValue/FieldDescription';
import FieldEmoji from './FieldValue/FieldEmoji';

import FieldNormal from './FieldValue/FieldNormal';
import FieldRadio from './FieldValue/FieldRadio';
import FieldStars from './FieldValue/FieldStars';
import FieldSubtitle from './FieldValue/FieldSubtitle';

import { Props as PropsInner } from './FieldValue/props';

import * as Styled from './styled';

type Props = {
  data: ContactFormDataFormatted;
};

/**
 * Map different concierge field types to their respective renderers
 * (null for skipped fields)
 */
const mapping: Record<
  conciergeFieldTypes.ConciergeFieldType,
  FC<PropsInner> | null
> = {
  [conciergeFieldTypes.TEXT]: FieldNormal,
  [conciergeFieldTypes.TEXT_AREA]: FieldNormal,
  [conciergeFieldTypes.CHECKBOX]: FieldCheckbox,
  [conciergeFieldTypes.RADIOBOX_GROUP]: FieldRadio,
  [conciergeFieldTypes.DATE]: FieldNormal,
  [conciergeFieldTypes.DATETIME]: FieldNormal,
  [conciergeFieldTypes.TIME]: FieldNormal,
  [conciergeFieldTypes.DROPDOWN]: FieldNormal,
  [conciergeFieldTypes.EMAIL]: FieldNormal,
  [conciergeFieldTypes.SUBMIT]: null,
  [conciergeFieldTypes.TITLE]: FieldNormal,
  [conciergeFieldTypes.SUBTITLE]: FieldSubtitle,
  [conciergeFieldTypes.DESCRIPTION]: FieldDescription,
  [conciergeFieldTypes.DIVIDER]: null,
  [conciergeFieldTypes.FEEDBACK_EMOJI]: FieldEmoji,
  [conciergeFieldTypes.FEEDBACK_STARS]: FieldStars,
  [conciergeFieldTypes.SIGNATURE]: null,
};

/**
 * Text field within the message is actually serialized contact form data,
 * so we parse and show that
 *
 * @param props      Props Passed to the component
 * @param props.data JSON data as string
 * @returns          The component itself
 */
const MessageConcierge: FC<Props> = ({ data }) => {
  return (
    <Styled.Dl>
      {data.fields.map((field, index) => {
        const Component = mapping[field.type];

        if (Component === null) {
          return null;
        }

        return (
          // There's no ID for a field, so we have to use index,
          // nothing else is available
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            <Component field={field} />
          </li>
        );
      })}
    </Styled.Dl>
  );
};

export default MessageConcierge;
