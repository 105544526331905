import { Divider } from '@mui/material';
import React, { FC } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useIsTablet from '../../../hooks/useIsTablet';
import translate from '../../../i18n/translate';
import { getSettingsUrl } from '../../../routes/urls/settings';

import DrawerEntry from '../../MUI/Drawer/DrawerContent/DrawerEntry';

import * as Styled from '../styled';

import { generateLinks, generateLinksExternal } from './links';

/**
 * List of settings items inside in desktop drawer when opened
 *
 * @returns Settings navigation component
 */
const SettingsNavigationDesktop: FC = () => {
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();
  const { pathname } = useLocation();

  const isMobile = false;
  const linksInternal = generateLinks(hotelId, isMobile, isTablet);
  const [profileLink, helpLink, ...linksExternal] = generateLinksExternal();
  const settingsHome = getSettingsUrl(hotelId);

  /**
   * Tells if currently iterated link is selected.
   * If we want to mark link as selected for only one url
   * than we pas single url that is type of string, else if we
   * want to mark link as selected for multiple urls than we pass
   * array of urls to match with.
   *
   * @param matchUrl String or array of strings to match with
   * @returns        Returns boolean
   */
  const getCurrentlySelected = (matchUrl: string | string[]): boolean => {
    // We're on settings homepage, no left sidebar link should be active
    if (pathname === settingsHome) {
      return false;
    }

    if (typeof matchUrl === 'string') {
      return matchPath(matchUrl, pathname) !== null;
    } else {
      return matchUrl.some(url => matchPath(url, pathname));
    }
  };

  return (
    <Styled.ScrollableList>
      {linksInternal.map(({ label, url, matchUrl = url }) => {
        return (
          <DrawerEntry
            href={url}
            isSelected={getCurrentlySelected(matchUrl)}
            key={label}
            label={translate(label)}
          />
        );
      })}
      <Divider />
      <DrawerEntry
        href={profileLink.url}
        isExternalLink={true}
        label={translate(profileLink.label)}
      />

      <DrawerEntry
        href={helpLink.url}
        isExternalLink={true}
        label={translate(helpLink.label)}
      />
      {linksExternal.map(({ label, url }) => {
        return (
          <DrawerEntry
            href={url}
            isExternalLink={true}
            key={label}
            label={translate(label)}
          />
        );
      })}
    </Styled.ScrollableList>
  );
};

export default SettingsNavigationDesktop;
