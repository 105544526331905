/**
 * @file All elevation (box-shadow) styles we use
 */

import { Shadows } from '@mui/material';

/**
 * Elevation styles (box-shadow values) used across the application.
 * These values map to Material-UI's elevation system.
 *
 * NOTE: MUI requires exactly 25 shadow levels (elevation={0} to elevation={24}).
 * If unique values are not needed for all levels, repetition is allowed.
 *
 * @see https://mui.com/material-ui/customization/theme-components/#default-props
 */
const shadows: Shadows = [
  'none',
  'var(--elevation-1)',
  'var(--elevation-2)',
  'var(--elevation-3)',
  'var(--elevation-4)',
  'var(--elevation-5)',
  'var(--elevation-6)',
  'var(--elevation-7)',
  'var(--elevation-8)',
  'var(--elevation-9)',
  'var(--elevation-10)',
  'var(--elevation-11)',
  'var(--elevation-12)',
  'var(--elevation-13)',
  'var(--elevation-14)',
  'var(--elevation-15)',
  'var(--elevation-16)',
  'var(--elevation-17)',
  'var(--elevation-18)',
  'var(--elevation-19)',
  'var(--elevation-20)',
  'var(--elevation-21)',
  'var(--elevation-22)',
  'var(--elevation-23)',
  'var(--elevation-24)',
];

export default shadows;
