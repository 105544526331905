/**
 * @file contains mapDispatchToProps function for devPanel container
 */

import { Dispatch } from 'redux';

import { DebugAction } from '../../models/debug';
import { MarkAsReadAction } from '../../models/markAsRead';
import {
  closeDebugPanel,
  setDebugHasMessages,
  setDebugNetworkComments,
  setDebugNetworkCompose,
  setDebugNetworkMessageDetails,
  setDebugNetworkMessages,
  setDebugNetworkQuickFilters,
  setDebugNetworkTopicBasics,
  setDebugNetworkTopicDetails,
  setDebugNetworkTopics,
  toggleDebugPanelState,
} from '../../store/actions/debug';
import {
  setMarkAsReadDebounce,
  setMarkAsReadDelay,
} from '../../store/actions/markAsRead';

import { DispatchProps } from './props';

/**
 * Map Redux dispatch to component props
 *
 * @param dispatch Redux dispatch callback
 * @returns        Props to inject into the component
 */
const mapDispatchToProps = (
  dispatch: Dispatch<DebugAction | MarkAsReadAction>,
): DispatchProps => ({
  /**
   * Dispatch request to close debug panel
   *
   * @returns void
   */
  requestClosePanel: () => dispatch(closeDebugPanel()),
  /**
   * Dispatch request to set has messages value
   *
   * @param state Current state of has messages
   *
   * @returns     void
   */
  requestSetHasMessages: state => dispatch(setDebugHasMessages(state)),
  /**
   * Dispatch request to set mark as read debounce
   *
   * @param state Current state of mark as read debounce
   */
  requestSetMarkAsReadDebounce: state => {
    dispatch(setMarkAsReadDebounce(state));
  },
  /**
   * Dispatch request to set mark as read delay
   *
   * @param state Current state of mark as read delay
   */
  requestSetMarkAsReadDelay: state => {
    dispatch(setMarkAsReadDelay(state));
  },
  /**
   * Dispatch request to set network comments
   *
   * @param state Current state of network comments
   */
  requestSetNetworkComments: state => {
    dispatch(setDebugNetworkComments(state));
  },
  /**
   * Dispatch request to set network compose
   *
   * @param state Current state of network compose
   */
  requestSetNetworkCompose: state => {
    dispatch(setDebugNetworkCompose(state));
  },
  /**
   * Dispatch request to set debug network message details
   *
   * @param state Current state of debug network message details
   */
  requestSetNetworkMessageDetails: state => {
    dispatch(setDebugNetworkMessageDetails(state));
  },
  /**
   * Dispatch request to set network messages
   *
   * @param state Current state of network messages
   */
  requestSetNetworkMessages: state => {
    dispatch(setDebugNetworkMessages(state));
  },
  /**
   * Dispatch request to set network quick filters
   *
   * @param state Current state of network quick filters
   */
  requestSetNetworkQuickFilters: state => {
    dispatch(setDebugNetworkQuickFilters(state));
  },
  /**
   * Dispatch request to set network topic basics
   *
   * @param state Current state of network topic basics
   */
  requestSetNetworkTopicBasic: state => {
    dispatch(setDebugNetworkTopicBasics(state));
  },
  /**
   * Dispatch request to set debug network topic details
   *
   * @param state Current state of debug network topic details
   */
  requestSetNetworkTopicDetails: state => {
    dispatch(setDebugNetworkTopicDetails(state));
  },
  /**
   * Dispatch request to set debug network topics
   *
   * @param state Current state of debug network topics
   *
   * @returns     void
   */
  requestSetNetworkTopics: state => dispatch(setDebugNetworkTopics(state)),
  /**
   * Dispatch request to toggle debug panel state
   *
   * @returns void
   */
  requestTogglePanel: () => dispatch(toggleDebugPanelState()),
});

export default mapDispatchToProps;
