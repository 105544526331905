import { SvgIconOwnProps } from '@mui/material';
import React, { FC } from 'react';

import useFocus from '../../../hooks/useFocus';

import MuiIcon from '../../MUI/MuiIcon';
import { MuiIconName, MuiSymbolIconName } from '../../MUI/MuiIcon/types';
import { NAMES } from '../Icon';
import Spinner from '../Spinner';

import * as Styled from './styled';

export type Props = {
  className?: HTMLButtonElement['className'];
  color?: string;
  colorHover?: string;
  fontSize?: SvgIconOwnProps['fontSize'];
  iconColor?: SvgIconOwnProps['color'];
  iconName: MuiIconName | MuiSymbolIconName;
  iconPosition?: 'left' | 'right';
  iconWidth?: number;
  isDisabled?: HTMLButtonElement['disabled'];
  isLoading?: boolean;
  label: NonNullable<HTMLButtonElement['ariaLabel']>;
  onClick?: () => void;
  type?: 'button' | 'submit';
  text?: string;
};

export const defaultColor = 'currentColor';
export const defaultType = 'button';

/**
 * Button with icon and optional text
 *
 * @param props              props passed to the component
 * @param props.className    styled-components generated class name, needed for styling
 * @param props.color        color of the button
 * @param props.colorHover   color of the icon when the button is hovered
 * @param props.fontSize     The font-size of the avatar initials
 * @param props.iconColor    color of the icon
 * @param props.iconName     which icon to show within the button
 * @param props.iconPosition Icon position in relation to text
 * @param props.iconWidth    the width of the included icon (button itself will be the same size)
 * @param props.isDisabled   Whether to make the button disabled
 * @param props.isLoading    Whether to show loading spinner
 * @param props.label        aria label for the button (since there's no text, just the icon)
 * @param props.onClick      click callback, not needed for submit buttons
 * @param props.type         <button> type attribute
 * @param props.text         Optional text
 * @returns                  the component itself
 */
const ButtonIconWithText: FC<Props> = ({
  className,
  color = defaultColor,
  colorHover = color,
  fontSize,
  iconColor = 'action',
  iconName,
  iconPosition = 'left',
  iconWidth,
  isDisabled = false,
  isLoading = false,
  label,
  onClick,
  type = defaultType,
  text,
  ...rest
}) => {
  const [, bind] = useFocus();

  return (
    <Styled.Button
      aria-label={label}
      className={className}
      data-color={color}
      data-color-hover={colorHover}
      data-position={iconPosition}
      isDisabled={isDisabled}
      label={label}
      onClick={onClick}
      type={type}
      {...rest}
      {...bind}
    >
      {isLoading ? (
        <Spinner size={iconWidth} />
      ) : (
        <MuiIcon
          color={iconColor}
          fontSize={fontSize}
          icon={iconName}
          width={iconWidth}
        />
      )}
      {text}
    </Styled.Button>
  );
};

export { NAMES };
export default ButtonIconWithText;
