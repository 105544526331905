import { styled } from 'styled-components';

import devices from '../../../styles/devices';

export const Card = styled.div`
  align-items: center;
  border: var(--border-dark);
  border-radius: var(--border-radius-big);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  justify-content: center;
  padding: var(--spacing-large);

  @media ${devices.tabletS} {
    height: 100%;
  }

  /* For devices with hover */
  @media (hover: hover) {
    &:hover {
      background-color: var(--color-background-selected);
      cursor: pointer;
    }
  }
`;
