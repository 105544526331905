import { ToastContainer as ToastifyToastContainer } from 'react-toastify';
import { styled } from 'styled-components';

import devices from '../../styles/devices';

export const ToastContainer = styled(ToastifyToastContainer)`
  margin-bottom: var(--preference-toast-container-margin-bottom);

  @media ${devices.tabletS} {
    margin-bottom: 0;
    width: var(--preference-toast-message-width-desktop);
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .Toastify__toast {
    background-color: transparent;
    box-shadow: none;
    padding: var(--spacing-small);
    width: 100%;

    @media ${devices.tabletS} {
      padding: 0;
    }
  }
`;
