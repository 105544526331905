import { Button, Badge as MuiBadge, styled } from '@mui/material';

import breakpoints from '../../../constants/breakpoints';

export const Badge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'var(--color-outline)',
    position: 'absolute',
    right: '-5px',
    top: '-5px',
    [theme.breakpoints.down(breakpoints.tabletL)]: {
      right: '-2px',
      top: '-5px',
    },
  },
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down(breakpoints.tabletL)]: {
    '& > .MuiButton-startIcon': {
      margin: '0',
    },
    '&.MuiButtonBase-root': {
      minWidth: 'var(--preference-loading-indicator-size)',
      padding: 'var(--spacing-small)',
    },
  },
}));
