import React, { FC, useRef } from 'react';

import useAttachmentAction from '../../../hooks/attachments/useAttachmentAction';
import {
  Attachment,
  AttachmentDraft,
  AttachmentMode,
} from '../../../models/attachment';

import { TypeMessageFull } from '../../../models/message';

import ComposeFragment from './ComposeFragment';
import DetailsEditFragment from './DetailsEditFragment';
import FeedFragment from './FeedFragment';

import PreviewFragment from './PreviewFragment';
import { getFileIcon, getIsUploading } from './helpers';

import * as Styled from './styled';

export type Props = {
  attachment: Attachment | AttachmentDraft;
  canDownloadFile?: boolean;
  message: TypeMessageFull;
  mode: AttachmentMode;
  requestFileRemove: () => void;
};

const iconHeight = 30;
const iconWidth = 20;

/**
 * Component that renders single attachment for existing messages
 *
 * @param props                   Props passed to the component
 * @param props.attachment        File that is attached
 * @param props.canDownloadFile   Whether user has permission to download attachment
 * @param props.message           The message itself
 * @param props.mode              In which mode to render the component
 * @param props.requestFileRemove Callback for removing file from the drafts array or message
 * @returns                       The component itself
 */
const AttachmentSingleExisting: FC<Props> = ({
  attachment,
  canDownloadFile = false,
  message,
  mode,
  requestFileRemove,
}) => {
  const { extension, key, name } = attachment;
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const previewLinkRef = useRef<HTMLAnchorElement>(null);
  const actionDownload = useAttachmentAction(
    'download',
    name,
    key,
    downloadLinkRef,
    message?.id,
  );
  const actionPreview = useAttachmentAction(
    'preview',
    name,
    key,
    previewLinkRef,
    message?.id,
  );

  const iconName = getFileIcon(extension);
  const isUploading = getIsUploading(attachment);

  return (
    <Styled.Wrapper data-mode={mode}>
      <PreviewFragment
        actionPreview={actionPreview}
        message={message}
        mode={mode}
      >
        <Styled.Icon height={iconHeight} name={iconName} width={iconWidth} />
        <Styled.Title>{name}</Styled.Title>
      </PreviewFragment>
      <DetailsEditFragment
        actionDownload={actionDownload}
        canDownloadFile={canDownloadFile}
        isArchived={message?.isArchived}
        isUploading={isUploading}
        mode={mode}
        requestButtonClick={requestFileRemove}
      />
      <Styled.ActionWrapper>
        <ComposeFragment
          isUploading={isUploading}
          mode={mode}
          requestButtonClick={requestFileRemove}
        />
      </Styled.ActionWrapper>
      <FeedFragment
        actionDownload={actionDownload}
        actionPreview={actionPreview}
        canDownloadFile={canDownloadFile}
        mode={mode}
      />
    </Styled.Wrapper>
  );
};

export default AttachmentSingleExisting;
