/**
 * @file contains Redux action types, listing all possibilities
 */

export const CHAT__UNREAD_INCREASE = 'CHAT__UNREAD_INCREASE';
export const CHAT__UNREAD_DECREASE = 'CHAT__UNREAD_DECREASE';
export const CHAT__UNREAD_SET = 'CHAT__UNREAD_SET';

export const DEBUG__HAS_MESSAGES = 'DEBUG__HAS_MESSAGES';
export const DEBUG__NETWORK__COMMENTS__SET = 'DEBUG__NETWORK__COMMENTS__SET';
export const DEBUG__NETWORK__COMPOSE__SET = 'DEBUG__NETWORK__COMPOSE__SET';
export const DEBUG__NETWORK__MESSAGE_DETAILS_SET =
  'DEBUG__NETWORK__MESSAGE_DETAILS_SET';
export const DEBUG__NETWORK__MESSAGES_SET = 'DEBUG__NETWORK__MESSAGES_SET';
export const DEBUG__NETWORK__QUICK_FILTERS__SET =
  'DEBUG__NETWORK__QUICK_FILTERS__SET';
export const DEBUG__NETWORK__TOPIC_BASIC__SET =
  'DEBUG__NETWORK__TOPIC_BASIC__SET';
export const DEBUG__NETWORK__TOPIC_DETAILS__SET =
  'DEBUG__NETWORK__TOPIC_DETAILS_SET';
export const DEBUG__NETWORK__TOPICS__SET = 'DEBUG__NETWORK__TOPICS__SET';
export const DEBUG__PANEL__CLOSE = 'DEBUG__PANEL__CLOSE';
export const DEBUG__PANEL__OPEN = 'DEBUG__PANEL__OPEN';
export const DEBUG__PANEL__TOGGLE = 'DEBUG__PANEL__TOGGLE';

export const DRAFTS__ASSIGNEE__GROUP__SET = 'DRAFTS__ASSIGNEE__GROUP__SET';
export const DRAFTS__ASSIGNEE__MEMBER__SET = 'DRAFTS__ASSIGNEE__MEMBER__SET';
export const DRAFTS__ASSIGNEE__SAVE = 'DRAFTS__ASSIGNEE__MEMBER__SAVE';
export const DRAFTS__ASSIGNEE__SEARCH__SET = 'DRAFTS__ASSIGNEE__SEARCH__SET';

export const DRAFTS__ATTACHMENT__ADD = 'DRAFTS__ATTACHMENT__ADD';
export const DRAFTS__ATTACHMENT__CLEAR = 'DRAFTS__ATTACHMENT__CLEAR';
export const DRAFTS__ATTACHMENT__INIT = 'DRAFTS__ATTACHMENT__INIT';
export const DRAFTS__ATTACHMENT__REMOVE = 'DRAFTS__ATTACHMENT__REMOVE';

export const DRAFTS__CHECKLIST__ADD = 'DRAFTS__CHECKLIST__ADD';
export const DRAFTS__CHECKLIST__SAVE = 'DRAFTS__CHECKLIST__SAVE';
export const DRAFTS__CHECKLIST__SET_STATE = 'DRAFTS__CHECKLIST__SET_STATE';
export const DRAFTS__CHECKLIST__SET_TEXT = 'DRAFTS__CHECKLIST__SET_TEXT';
export const DRAFTS__CHECKLIST__REORDER = 'DRAFTS__CHECKLIST__REORDER';
export const DRAFTS__CHECKLIST__REMOVE = 'DRAFTS__CHECKLIST__REMOVE';

export const DRAFTS__DATE_DUE__SET = 'DRAFTS__DATE_DUE__SET';
export const DRAFTS__DATE_DUE__SAVE = 'DRAFTS__DATE_DUE__SAVE';

export const DRAFTS__EDIT_TEMPLATE_DATA_SAVE =
  'DRAFTS__EDIT_TEMPLATE_DATA_SAVE';

export const DRAFTS__LABEL__ADD = 'DRAFTS__LABEL__ADD';
export const DRAFTS__LABEL__SAVE = 'DRAFTS__LABEL__SAVE';
export const DRAFTS__LABEL__REMOVE = 'DRAFTS__LABEL__REMOVE';

export const DRAFTS__MAIN__INIT = 'DRAFTS__MAIN__INIT';
export const DRAFTS__MAIN__CLEAR = 'DRAFTS__MAIN__CLEAR';
export const DRAFTS__MAIN__POPULATE = 'DRAFTS__MAIN__POPULATE';
export const DRAFTS__MAIN__TITLE_SET = 'DRAFTS__MAIN__TITLE_SET';
export const DRAFTS__MAIN__TOPIC_SAVE = 'DRAFTS__MAIN__TOPIC_SAVE';
export const DRAFTS__MAIN__TOPIC_SET = 'DRAFTS__MAIN__TOPIC_SET';

export const DRAFTS__OFFSET__SAVE = 'DRAFTS__OFFSET__SAVE';
export const DRAFTS__OFFSET__SET = 'DRAFTS__OFFSET__SET';

export const DRAFTS__REMINDERS__ADD = 'DRAFTS__REMINDERS__ADD';
export const DRAFTS__REMINDERS__SAVE = 'DRAFTS__REMINDERS__SAVE';
export const DRAFTS__REMINDERS__SET = 'DRAFTS__REMINDERS__SET';
export const DRAFTS__REMINDERS__REMOVE = 'DRAFTS__REMINDERS__REMOVE';

export const NATIVE_APP__DEVICE_INFO__SET = 'NATIVE_APP__DEVICE_INFO__SET';
export const NATIVE_APP__DOWNLOAD_INFO__SET = 'NATIVE_APP__DOWNLOAD_INFO__SET';
export const NATIVE_APP__FIREBASE_INFO__SET = 'NATIVE_APP__FIREBASE_INFO__SET';

export const RECURRING__DRAFT__INIT = 'RECURRING__DRAFT__INIT';
export const RECURRING__DRAFT__POPULATE = 'RECURRING__DRAFT__POPULATE';
export const RECURRING__DRAFT__RESET = 'RECURRING__DRAFT__RESET';
export const RRULE__BY_MONTH_DAY__SAVE = 'RRULE__BY_MONTH_DAY__SAVE';
export const RRULE__BY_MONTH_DAY__SET = 'RRULE__BY_MONTH_DAY__SET';
export const RRULE__BY_SET_POS__SAVE = 'RRULE__BY_SET_POS__SAVE';
export const RRULE__BY_WEEK_DAY__SAVE = 'RRULE__BY_WEEK_DAY__SAVE';
export const RRULE__BY_WEEK_DAY_SINGLE__SAVE =
  'RRULE__BY_WEEK_DAY_SINGLE__SAVE';
export const RRULE__DTSTART__SAVE = 'RRULE__DTSTART__SAVE';
export const RRULE__DTSTART__SET = 'RRULE__DTSTART__SET';
export const RRULE__FREQUENCY__SAVE = 'RRULE__FREQUENCY__SAVE';
export const RRULE__INTERVAL__SAVE = 'RRULE__INTERVAL__SAVE';
export const RRULE__UNTIL__SAVE = 'RECURRING__DURATION__SAVE';
export const RRULE__UNTIL__SET = 'RRULE__UNTIL__SET';

export const DRAFTS__TEMPLATE__RESET = 'DRAFTS__TEMPLATE__RESET';
export const DRAFTS__TEMPLATE__SET = 'DRAFTS__TEMPLATE__SET';
export const DRAFTS__TEMPLATE__SAVE = 'DRAFTS__TEMPLATE__SAVE';
export const DRAFTS__TEMPLATE__SEARCH__SET = 'DRAFTS__TEMPLATE__SEARCH__SET';

export const DRAFTS__TEMPLATE__TOPIC__SET = 'DRAFTS__TOPIC__SET';
export const DRAFTS__TEMPLATE__TOPIC__SAVE = 'DRAFTS__TOPIC__SAVE';
export const DRAFTS__TEMPLATE__TOPIC__SEARCH__SET =
  'DRAFTS__TOPIC__SEARCH__SET';

export const DRAFTS__TEXT__SET = 'DRAFTS__TEXT__SET';

export const MARK_AS_READ__DEBOUNCE = 'MARK_AS_READ__DEBOUNCE';
export const MARK_AS_READ__DELAY = 'MARK_AS_READ__DELAY';

export const SCROLL__REMEMBER__FEED = 'SCROLL__REMEMBER__FEED';
export const SCROLL__REMEMBER__SIDEBAR = 'SCROLL__REMEMBER__SIDEBAR';
export const SCROLL__RESET__FEED = 'SCROLL__RESET__FEED';

export const SCROLL__TOPICS__LATEST_POST__ADD =
  'SCROLL__TOPICS__LATEST_POST__ADD';
export const SCROLL__TOPICS__LATEST_POST__REMOVE =
  'SCROLL__TOPICS__LATEST_POST__REMOVE';

export const SEARCH__PREDEFINED_QUERY_SET = 'SEARCH__PREDEFINED_QUERY_SET';
export const SEARCH__TEXT_QUERY_SET = 'SEARCH__TEXT_QUERY_SET';

export const SUBSCRIPTION__COMMENTS_SUBSCRIBE =
  'SUBSCRIPTION__COMMENTS_SUBSCRIBE';
export const SUBSCRIPTION__COMMENTS_UNSUBSCRIBE =
  'SUBSCRIPTION__COMMENTS_UNSUBSCRIBE';
export const SUBSCRIPTION__MESSAGES_SUBSCRIBE =
  'SUBSCRIPTION__MESSAGES_SUBSCRIBE';
export const SUBSCRIPTION__MESSAGES_UNSUBSCRIBE =
  'SUBSCRIPTION__MESSAGES_UNSUBSCRIBE';

export const TRANSLATIONS__TRANSLATE_MESSAGE =
  'TRANSLATIONS__TRANSLATE_MESSAGE';
export const TRANSLATIONS__UNTRANSLATE_MESSAGE =
  'TRANSLATIONS__UNTRANSLATE_MESSAGE';

export const UI__MESSAGE_EDIT__MODE = 'UI__MESSAGE_EDIT__MODE';
