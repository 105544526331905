import React, { FC } from 'react';

import translate from '../../../i18n/translate';

import * as Styled from './styled';

export type Props = {
  color: string;
  title: string;
  onClick: () => void;
};

const ICON_SIZE = 15;

/**
 * Button to edit a label
 *
 * @param props         Props passed to the component
 * @param props.color   Which color to make the icon
 * @param props.onClick On button click callback
 * @param props.title   Label title, used to generate HTML label
 * @returns             The component itself
 */
const LabelPickerButtonEdit: FC<Props> = ({ color, onClick, title }) => {
  const label = `${translate('GENERAL__EDIT')} ${title}`;
  return (
    <Styled.ButtonIcon
      color={color}
      fontSize="small"
      iconHeight={ICON_SIZE}
      iconName="createOutlined"
      iconWidth={ICON_SIZE}
      label={label}
      onClick={onClick}
    />
  );
};

export default LabelPickerButtonEdit;
