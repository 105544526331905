import { styled } from 'styled-components';

type ProgressProps = {
  'data-color': string;
};

/**
 * Get the color to use for link text
 *
 * @param props The props passed to the component
 * @returns     The color to use
 */
const getBackgroundColor = (props: ProgressProps) => {
  return props['data-color'];
};

/**
 * HTML progress bar element,
 * styled to cover Chromium but also Safari and Firefox
 *
 * @param props Props passed
 * @returns     The progress bar
 */
export const ProgressBar = styled.progress<ProgressProps>`
  /* Get rid of default border in Firefox. */
  border: none;
  border-radius: var(--border-radius-tiny);
  height: 0.5rem;
  width: 100%;

  &::-moz-progress-bar {
    background-color: ${getBackgroundColor};
    border-radius: var(--border-radius-tiny);
  }

  &::-webkit-progress-bar {
    background-color: var(--color-border-dark);
    border-radius: var(--border-radius-tiny);
  }

  &::-webkit-progress-value {
    background-color: ${getBackgroundColor};
    border-radius: var(--border-radius-tiny);
  }
`;
