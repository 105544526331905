/**
 * @file contains helper functions for AutomaticTranslationForm component
 */

import { MuiSelectItem } from '../../../components/MUI/MuiSelect';
import {
  Maybe,
  TargetLanguage,
  UserLocalizationQuery,
} from '../../../generated/graphql';
import translate from '../../../i18n/translate';

/**
 * Create target language dropdown options.
 *
 * @param targetLanguagesData Data we get from useTargetLanguagesQuery
 * @returns                   Array of target language option or empty array
 */
export const getTargetLanguageOptions = (
  targetLanguagesData: UserLocalizationQuery | undefined,
): MuiSelectItem[] => {
  return (
    targetLanguagesData?.localization.targetLanguages.map(
      ({ label, value }) => {
        return { label, value: value ?? null };
      },
    ) ?? []
  );
};

/**
 * Construct the default select value
 *
 * @param language            Currently selected language
 * @param targetLanguagesData Data we get from useTargetLanguagesQuery
 * @returns                   Target language default value
 */
export const getTargetLanguageDefaultValue = (
  language: Maybe<TargetLanguage>,
  targetLanguagesData: UserLocalizationQuery | undefined,
): MuiSelectItem => {
  const languageData = targetLanguagesData?.localization.targetLanguages.find(
    lang => lang.value === language,
  );

  if (languageData?.value === undefined || languageData.value === null) {
    return {
      label: translate('GENERAL__NONE'),
      value: '',
    };
  }

  return {
    label: languageData.label,
    value: languageData.value,
  };
};
