/**
 * @file Contains theme and component configuration for Material UI
 */

import { createTheme } from '@mui/material/styles';

import shadows from '../../constants/elevationStyles';

import {
  colorError,
  colorNeutral,
  colorPrimary,
  colorSecondary,
  colorTertiary,
} from './colors';
import components from './components';

const baseTheme = createTheme({
  components,
  shadows: shadows,
  typography: {
    body1: {
      fontSize: 'var(--font-baseline)',
    },
    body2: {
      fontSize: 'var(--font-sm)',
    },
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      "'Segoe UI'",
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      "'Open Sans'",
      "'Helvetica Neue'",
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: 'var(--font-xl)',
    },
    h2: {
      fontSize: 'var(--font-lg)',
      fontWeight: 'var(--font-weight-heading)',
    },
    h3: {
      fontSize: 'var(--font-baseline)',
      fontWeight: 'var(--font-semibold)',
    },
  },
});

const palette = {
  error: baseTheme.palette.augmentColor({
    color: colorError,
    name: 'error',
  }),
  neutral: baseTheme.palette.augmentColor({
    color: colorNeutral,
    name: 'neutral',
  }),
  primary: baseTheme.palette.augmentColor({
    color: colorPrimary,
    name: 'primary',
  }),
  secondary: baseTheme.palette.augmentColor({
    color: colorSecondary,
    name: 'secondary',
  }),
  tertiary: baseTheme.palette.augmentColor({
    color: colorTertiary,
    name: 'tertiary',
  }),
};

const muiTheme = createTheme(baseTheme, {
  palette,
});

export { muiTheme, palette };
