import { Skeleton } from '@mui/material';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import {
  TargetLanguage,
  UserLocalizationDocument,
} from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import MuiSelect from '../../MUI/MuiSelect';

import {
  getTargetLanguageDefaultValue,
  getTargetLanguageOptions,
} from './helpers';
import * as Styled from './styled';

/**
 * @todo Instead of hardcoding this create skeleton component
 * that accepts any component as children and infers dimensions from it.
 * @see https://mui.com/material-ui/react-skeleton/#inferring-dimensions
 */
const SKELETON_HEIGHT = 60;

type Props = {
  currentUserLanguage?: TargetLanguage | null;
  formId?: string;
  requestSave: (text: TargetLanguage) => void;
};

/**
 * Automatic translation form
 *
 * @param props                     Props passed to the component
 * @param props.currentUserLanguage Target language of the current user
 * @param props.formId              The formId, optional argument
 * @param props.requestSave         Callback that updates translation language
 * @returns                         The component itself
 */
const AutomaticTranslationForm: FC<Props> = ({
  currentUserLanguage = null,
  formId,
  requestSave,
}) => {
  const [{ data, fetching }] = useQuery({
    query: UserLocalizationDocument,
    variables: {},
  });

  const targetLanguageOptions = getTargetLanguageOptions(data);
  const defaultValue = getTargetLanguageDefaultValue(currentUserLanguage, data);

  return (
    <Styled.Form id={formId} onSubmit={event => event.preventDefault()}>
      {fetching ? (
        <Skeleton height={SKELETON_HEIGHT} variant="rounded" />
      ) : (
        <MuiSelect
          defaultValue={defaultValue}
          label={translate('MODAL__USER_UPDATE_LANGUAGE')}
          options={targetLanguageOptions}
          requestChange={newValue => {
            void requestSave(newValue.value as TargetLanguage);
          }}
        />
      )}
    </Styled.Form>
  );
};

export default AutomaticTranslationForm;
