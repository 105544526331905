import { ComponentPropsWithRef } from 'react';
import { HexColorInput } from 'react-colorful';
import { css, IStyledComponent, styled } from 'styled-components';

const pointerStyles = css`
  border: 1px solid var(--color-text-alt);
  cursor: pointer;
  height: 12px;
  width: 12px;
`;

export const Wrapper = styled.div`
  & .react-colorful {
    /**
     * Initially it's 200px high, but that breaks our design
     * and vertical scroll bar appears
     */
    height: 199px;
    overflow: hidden;

    /** Set padding so pointer does not get cut off. Use 6px since pointer is 12px. */
    padding: var(--border-radius-small);
    padding-bottom: 0;
    width: 100%;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  & .react-colorful__saturation {
    border-bottom: 0;
    border-radius: 0;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  & .react-colorful__pointer {
    ${pointerStyles}
  }

  /* stylelint-disable-next-line selector-class-pattern */
  & .react-colorful__hue {
    border-radius: 0;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  & .react-colorful__hue-pointer {
    ${pointerStyles}
  }
`;

export const HexInput: IStyledComponent<
  'web',
  ComponentPropsWithRef<typeof HexColorInput>
> = styled(HexColorInput)`
  border: var(--border-dark);
  border-radius: var(--border-radius-tiny);
  font-size: var(--font-baseline);
  margin: var(--spacing-tiny) 0;
  padding: var(--spacing-tiny) var(--spacing-small);
  width: 100%;
`;

export const HexInputLabel = styled.label`
  display: inline-block;
  font-weight: var(--font-weight-label-bold);
  margin-top: var(--spacing-medium);
  width: 100%;
`;
