/**
 * @file Helper functions for AppLayout
 */
import { RightAsideProps } from './styledProps';

/**
 * Get max-width for the right sidebar
 * (only should be applied when it's open)
 *
 * @param props Props passed to the component
 * @returns     Valid value for max width property
 */
export const getRightSidebarMaxWidthDesktop = (
  props: RightAsideProps,
): string => {
  if (props['data-should-show-desktop']) {
    return 'var(--preference-right-sidebar-open-max-width-desktop)';
  }

  return 'none';
};

/**
 * Get min-width for the right sidebar
 * (only should be applied when it's open)
 *
 * @param props Props passed to the component
 * @returns     Valid value for min width property
 */
export const getRightSidebarMinWidthDesktop = (
  props: RightAsideProps,
): string => {
  if (props['data-should-show-desktop']) {
    return 'var(--preference-right-sidebar-open-min-width-desktop)';
  }

  return '0';
};
