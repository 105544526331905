import { ListItem, ListItemButton, ListItemIcon, styled } from '@mui/material';

export const MuiListItem = styled(ListItem)({
  color: 'var(--color-blue-dark)',
  padding: 0,
});

export const MuiListItemIcon = styled(ListItemIcon)({
  color: 'var(--color-blue-dark)',
  marginRight: 'var(--spacing-medium)',
  minWidth: 'auto',
});

export const MuiListItemButton = styled(ListItemButton)({
  padding: 'var(--spacing-medium)',
});
