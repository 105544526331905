import React, { FC } from 'react';

import { Navigate } from 'react-router-dom';

import useToast from '../../../hooks/useToast';

import translate from '../../../i18n/translate';

import useRedirectPath from './hooks/useRedirectPath';

/**
 * Proxy route that redirects users after they get notifications
 *
 * @returns The component itself
 */
const PageProxy: FC = () => {
  const pushToast = useToast();

  const [shouldPushToast, redirectUrl] = useRedirectPath();

  // There was a problem, push 404 toast
  if (shouldPushToast) {
    pushToast({ text: translate('ERROR__404__DESCRIPTION'), type: 'error' });
  }

  // The data is being fetched + validated, waiting
  if (redirectUrl === null) {
    return null;
  }

  return <Navigate to={redirectUrl} />;
};

export default PageProxy;
