import { Button, Card, CardActions, CardContent } from '@mui/material';
import { styled } from 'styled-components';

import { ToastMessageType } from '../../../models/toastMessage';

import MuiIcon from '../../MUI/MuiIcon';

import { getToastColorBackground, getToastColorIcon } from './helpers';

type MessageProps = {
  'data-message-type': ToastMessageType;
};

type ToggleButtonIconProps = {
  'data-is-expanded': boolean;
};

export const ToastWrapper = styled.div`
  width: 100%;
`;

export const ToastCard = styled(Card)<MessageProps>(props => ({
  '&.MuiCard-root': {
    background: getToastColorBackground(props),
    borderRadius: 'var(--border-radius-large)',
    boxShadow: 'var(--box-shadow-toast)',
    color: 'var(--color-toast-text)',
    fontSize: 'var(--font-baseline)',
  },

  width: '100%',
}));

export const ToastCardContent = styled(CardContent)({
  '&.MuiCardContent-root': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 'var(--spacing-small)',
    padding: 'var(--spacing-medium)',
    paddingBottom: 'var(--spacing-small)',
  },
});

export const ToastCardFooter = styled(CardActions)({
  '&.MuiCardActions-root': {
    display: 'inline-grid',
    gridTemplateAreas: '"toggleButton actionButton"',
    gridTemplateColumns: '1fr 1fr',
    padding: 'var(--spacing-medium)',
    paddingTop: '0',
    width: '100%',
  },
});

export const ToastCardCollapseContent = styled(CardContent)({
  '&.MuiCardContent-root:last-child': {
    padding: '0 var(--spacing-small)',
    paddingBottom: 'var(--spacing-small)',
    paddingLeft: '48px',
  },
});

export const Icon = styled(MuiIcon)<MessageProps>`
  align-self: flex-start;
  color: ${getToastColorIcon};
`;

const BaseButton = styled(Button)({
  '&.MuiButtonBase-root:focus': {
    backgroundColor: 'var(--color-toast-button-focus)',
  },
  '&.MuiButtonBase-root:hover': {
    backgroundColor: 'var(--color-toast-button-hover)',
  },
});

export const ActionButton = styled(BaseButton)({
  gridArea: 'actionButton',
  justifySelf: 'end',
});

export const ToggleButton = styled(BaseButton)({
  gridArea: 'toggleButton',
  justifySelf: 'start',
});

export const ToggleButtonIcon = styled(MuiIcon)<ToggleButtonIconProps>(
  props => ({
    transform: props['data-is-expanded'] ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.2s ease-in-out !important',
  }),
);
