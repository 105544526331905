import React, { FC } from 'react';

import { Slide } from 'react-toastify';

import {
  TOAST__MESSAGE_DURATION,
  TOAST__MESSAGE_LIMIT,
} from '../../constants/preferences';

import * as Styled from './styled';

/**
 * Styled ToastContainer component used for displaying toasts
 *
 * @returns The component itself
 */
const ToastContainer: FC = () => {
  return (
    <Styled.ToastContainer
      autoClose={TOAST__MESSAGE_DURATION}
      closeButton={false}
      hideProgressBar={true}
      icon={false}
      limit={TOAST__MESSAGE_LIMIT}
      position="bottom-right"
      transition={Slide}
    />
  );
};

export default ToastContainer;
