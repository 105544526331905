import React, { FC } from 'react';

import { IconPropsExtended } from '../types';

import MaterialSymbolIconBase from './MaterialSymbolIconBase';

/**
 * Material symbol download icon
 *
 * @param props Props passed to the component
 * @returns     Download icon
 */
const MaterialSymbolDownloadIcon: FC<IconPropsExtended> = props => (
  <MaterialSymbolIconBase {...props} iconName="download" />
);

export default MaterialSymbolDownloadIcon;
