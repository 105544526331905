import { Stack } from '@mui/material';
import React, { FC } from 'react';

import { HASH_MESSAGE__INFO__DESCRIPTION } from '../../../../constants/routing/hashLinks';
import { getMessageText } from '../../../../formatters/concierge/form';
import { formatConciergeData } from '../../../../formatters/concierge/formMessagingApi';
import { MessageType } from '../../../../generated/graphql';
import useTranslation from '../../../../hooks/store/useTranslation';
import { MessageFeed } from '../../../../models/message';
import { getUrlWithHash } from '../../../../routes/helpers/hash';
import MessageConcierge from '../../../Common/MessageConcierge';
import RichText from '../../../Common/RichText';
import * as Styled from '../styled';

type Props = { task: MessageFeed; url: string };

/**
 * Content of task description
 *
 * @param props      Props passed to the component
 * @param props.task The task to show the text of
 * @param props.url  Message details URL
 * @returns          The component itself
 */
const TaskDescription: FC<Props> = ({ task, url }) => {
  const translation = useTranslation(task.id);

  const to = getUrlWithHash(url, HASH_MESSAGE__INFO__DESCRIPTION);

  if (task.type !== MessageType.CONCIERGE_FORM) {
    const textContent = translation?.text ?? getMessageText(task);

    return (
      <Styled.LinkText to={to}>
        <RichText shouldRenderLinks={false}>{textContent}</RichText>
      </Styled.LinkText>
    );
  }

  const data = formatConciergeData(task.text, task.thread);
  if (data === null) {
    return null;
  }

  return (
    <Stack spacing={1}>
      {data.description && (
        <Styled.LinkText to={to}>
          <RichText shouldRenderLinks={false}>{data.description}</RichText>
        </Styled.LinkText>
      )}
      <Styled.LinkText
        data-line-clamp="var(--preference-task-description-concierge-line-clamp)"
        to={to}
      >
        <MessageConcierge data={data} />
      </Styled.LinkText>
    </Stack>
  );
};

export default TaskDescription;
