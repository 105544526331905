import Tippy from '@tippyjs/react';
import React, { FC, ReactNode } from 'react';

import translate from '../../../i18n/translate';
import { AttachmentAction, AttachmentMode } from '../../../models/attachment';

import { TypeMessageFull } from '../../../models/message';
import { getIsPwaIos } from '../../../utils/webview/helpers';

import { shouldRenderPreviewFragment } from './helpers';
import * as Styled from './styled';

type Props = {
  actionPreview: AttachmentAction;
  children: ReactNode;
  message: TypeMessageFull;
  mode: AttachmentMode;
};

/**
 * Fragment of AttachmentSingle used for file preview
 * (rendered in detailsEdit mode)
 *
 * @param props               Props passed to the component
 * @param props.actionPreview Attachment action preview props
 * @param props.children      Children passed to the component
 * @param props.mode          Attachment mode
 * @param props.message       The message itself
 * @returns                   The component itself
 */
const PreviewFragment: FC<Props> = ({
  actionPreview,
  children,
  message,
  mode,
}) => {
  const isPwaIos = getIsPwaIos();

  // Only render preview button/link on desktop details/detailsEdit and mobile details
  if (shouldRenderPreviewFragment(mode, message?.id)) {
    return (
      <>
        <Tippy content={translate('FILE__PREVIEW')} touch={false}>
          <Styled.ButtonPreview
            disabled={message.isArchived || actionPreview.isInProgress}
            onClick={actionPreview.requestButtonClick}
            type="button"
          >
            {children}
          </Styled.ButtonPreview>
        </Tippy>
        <Styled.HiddenLink
          aria-hidden="true"
          href={actionPreview.link}
          ref={actionPreview.linkRef}
          /**
           * Open the preview in the same tab on iOS PWA.
           * In Safari on iOS default setting is to block popups so preview would not open.
           */
          target={isPwaIos ? '_self' : '_blank'}
        />
      </>
    );
  }

  return <>{children}</>;
};

export default PreviewFragment;
