import React, { FC } from 'react';

import translate from '../../../i18n/translate';

import { ToastMessageType } from '../../../models/toastMessage';

import * as Styled from './styled';

export type Props = {
  isExpanded: boolean;
  requestToggleAdditionalText: () => void;
  type: ToastMessageType;
};

/**
 * Toggle the visibility of the additional text
 *
 * @param props                             Props passed to the component
 * @param props.isExpanded                  Whether the additional text is currently visible
 * @param props.requestToggleAdditionalText Callback to invoke on button click
 * @param props.type                        Type of the toast message
 * @returns                                 The component itself
 */
const ToastToggle: FC<Props> = ({
  isExpanded,
  requestToggleAdditionalText,
  type,
}) => {
  const label = translate(
    isExpanded ? 'TOAST__HIDE_DETAILS_TEXT' : 'TOAST__SHOW_DETAILS_TEXT',
  );

  return (
    <Styled.ToggleButton
      aria-label={label}
      data-message-type={type}
      onClick={requestToggleAdditionalText}
      startIcon={
        <Styled.ToggleButtonIcon
          data-is-expanded={isExpanded}
          icon="expandMore"
        />
      }
    >
      {label}
    </Styled.ToggleButton>
  );
};

export default ToastToggle;
