import React, { FC } from 'react';

import Icon, { NAMES } from '../../../Icon';

import { Props } from '../props';

/**
 * Star rating field, we show full stars up to the rating
 * and outlined for the rest
 *
 * @param props             Props passed to the component
 * @param props.field       The field to show
 * @param props.field.value Concierge field value
 * @returns                 The component itself
 */
const FieldStars: FC<Props> = ({ field: { value } }) => {
  const rating = parseInt(value ?? '0', 10);
  const max = 5;

  return (
    <ul>
      {Array.from({ length: rating }).map((_, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <Icon key={index} name={NAMES.CONCIERGE__STAR_FULL} />;
      })}
      {Array.from({ length: max - rating }).map((_, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <Icon key={index} name={NAMES.CONCIERGE__STAR_OUTLINE} />;
      })}
    </ul>
  );
};

export default FieldStars;
