// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roq-chat-message-bell {
  height: calc(var(--spacing-medium) + var(--spacing-tiny));
  min-width: calc(var(--spacing-medium) + var(--spacing-tiny));
}

.roq-chat-message-bell__button__icon {
  align-items: center;
  display: flex;
  justify-content: center;
}

.roq-chat-message-bell__badge .roq-badge__count {
  background-color: var(--color-background-notification);
  border-radius: var(--border-radius-huge);
  color: var(--color-text-alt);
  font-size: var(--font-sm);
  font-weight: var(--font-weight-label-bold);
  height: var(--size-badge-notification);
  min-width: var(--size-badge-notification);
  padding: 0 var(--spacing-tiny);
  position: absolute;
  right: calc(var(--spacing-medium) + var(--spacing-tiny));
  top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/roqChat/_bell-icon.css"],"names":[],"mappings":"AAAA;EACE,yDAAyD;EACzD,4DAA4D;AAC9D;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,sDAAsD;EACtD,wCAAwC;EACxC,4BAA4B;EAC5B,yBAAyB;EACzB,0CAA0C;EAC1C,sCAAsC;EACtC,yCAAyC;EACzC,8BAA8B;EAC9B,kBAAkB;EAClB,wDAAwD;EACxD,MAAM;AACR","sourcesContent":[".roq-chat-message-bell {\n  height: calc(var(--spacing-medium) + var(--spacing-tiny));\n  min-width: calc(var(--spacing-medium) + var(--spacing-tiny));\n}\n\n.roq-chat-message-bell__button__icon {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n}\n\n.roq-chat-message-bell__badge .roq-badge__count {\n  background-color: var(--color-background-notification);\n  border-radius: var(--border-radius-huge);\n  color: var(--color-text-alt);\n  font-size: var(--font-sm);\n  font-weight: var(--font-weight-label-bold);\n  height: var(--size-badge-notification);\n  min-width: var(--size-badge-notification);\n  padding: 0 var(--spacing-tiny);\n  position: absolute;\n  right: calc(var(--spacing-medium) + var(--spacing-tiny));\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
