import { styled } from 'styled-components';

import { CssVariable } from '../../styles/cssTypes';
import devices from '../../styles/devices';
import ButtonSaveCommon from '../Common/ButtonSave';

type WrapperProps = {
  'data-is-popup': boolean;
};

/**
 * Get the amount of padding to apply to the wrapper
 *
 * @param props Props passed to the wrapper
 * @returns     The variable to use
 */
const getWrapperPadding = (props: WrapperProps): CssVariable => {
  if (props['data-is-popup']) {
    return 'var(--spacing-small)';
  }

  return 'var(--spacing-medium)';
};

export const Wrapper = styled.div<WrapperProps>`
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  height: ${props => (props['data-is-popup'] ? 'auto' : '100%')};

  @media ${devices.tabletS} {
    padding: ${getWrapperPadding};
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: var(--spacing-medium);
  position: relative;

  /*
   * Without it, on mobile, post edit title/description form
   * is not above the message full dropdowns
   */
  z-index: var(--z-1);

  @media ${devices.tabletS} {
    padding: 0;
  }
`;

export const Footer = styled.footer`
  background-color: var(--color-background-selected);

  /* @todo Check with Laura whether it's better to keep this vertically centered */
  padding: var(--spacing-small) var(--spacing-medium);

  @media ${devices.tabletS} {
    background-color: inherit;
    padding: var(--spacing-small) 0 0;
  }
`;

export const ButtonSave = styled(ButtonSaveCommon)`
  padding: var(--spacing-small) 0;
  width: 100%;
`;

export const WarningWrapper = styled.div`
  display: flex;
  margin-bottom: var(--spacing-small);
`;
