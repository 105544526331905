import { styled } from 'styled-components';

import ButtonCommon from '../../../Button';

export const Button = styled(ButtonCommon)`
  background-color: var(--color-background-tools);
  border: var(--border-darker);
  border-radius: var(--border-radius-tiny);
  color: var(--color-border-darker);
  padding: var(--spacing-tiny) var(--spacing-large);
  transition:
    background-color var(--preference-transition-duration-normal),
    color var(--preference-transition-duration-normal);

  &:disabled {
    background-color: var(--color-background-tools);
  }

  &:not(:disabled) {
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-light);
    color: var(--color-text-alt);
  }
`;
