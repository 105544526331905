/**
 * @file MUI Icon mapping
 */

import AccountCircle from '@mui/icons-material/AccountCircleOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Apartment from '@mui/icons-material/ApartmentRounded';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import AttachFile from '@mui/icons-material/AttachFileRounded';
import ChatBubbleOutline from '@mui/icons-material/ChatBubbleOutlineRounded';
import CheckCircle from '@mui/icons-material/CheckCircleOutlineRounded';
import Check from '@mui/icons-material/CheckRounded';
import ChevronLeft from '@mui/icons-material/ChevronLeftRounded';
import ChevronRight from '@mui/icons-material/ChevronRightRounded';
import CloseIcon from '@mui/icons-material/Close';
import ContentPasteOutlined from '@mui/icons-material/ContentPasteOutlined';
import Copyright from '@mui/icons-material/CopyrightRounded';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterList from '@mui/icons-material/FilterListRounded';
import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded';
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import GppMaybeOutlined from '@mui/icons-material/GppMaybeOutlined';
import GroupsOutlined from '@mui/icons-material/GroupsOutlined';
import HelpOutline from '@mui/icons-material/HelpOutlineRounded';
import LabelOutlined from '@mui/icons-material/LabelOutlined';
import LocalHotelOutlined from '@mui/icons-material/LocalHotelOutlined';
import LockOpen from '@mui/icons-material/LockOpenRounded';
import LockOutlined from '@mui/icons-material/LockOutlined';
import Logout from '@mui/icons-material/LogoutRounded';
import MessageOutlined from '@mui/icons-material/MessageOutlined';
import ModeEditOutlineOutlined from '@mui/icons-material/ModeEditOutlineOutlined';
import MoreVert from '@mui/icons-material/MoreVert';
import NotificationsNoneRounded from '@mui/icons-material/NotificationsNoneRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import PersonOutline from '@mui/icons-material/PersonOutlineRounded';
import RefreshRounded from '@mui/icons-material/RefreshRounded';
import Search from '@mui/icons-material/SearchRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import SettingsSuggest from '@mui/icons-material/SettingsSuggestRounded';
import Speed from '@mui/icons-material/SpeedRounded';
import StrikethroughSRoundedIcon from '@mui/icons-material/StrikethroughSRounded';
import SwapVerticalCircleOutlined from '@mui/icons-material/SwapVerticalCircleOutlined';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import Topic from '@mui/icons-material/TopicOutlined';
import Translate from '@mui/icons-material/TranslateRounded';
import Tune from '@mui/icons-material/TuneRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import WestRounded from '@mui/icons-material/WestRounded';

import { FC } from 'react';

import MaterialSymbolClockIcon from './MaterialSymbolIcon/Clock';
import MaterialSymbolClockFilledIcon from './MaterialSymbolIcon/ClockFilled';
import MaterialSymbolDownloadIcon from './MaterialSymbolIcon/Download';
import MaterialSymbolErrorIcon from './MaterialSymbolIcon/Error';
import MaterialSymbolHomeIcon from './MaterialSymbolIcon/Home';
import MaterialSymbolInfoIcon from './MaterialSymbolIcon/Info';
import MaterialSymbolSettingsIcon from './MaterialSymbolIcon/Settings';
import MaterialSymbolWarningIcon from './MaterialSymbolIcon/Warning';
import { IconPropsExtended, MuiIconName, MuiSymbolIconName } from './types';

export const muiIconMappings: Record<MuiIconName, typeof AccountCircle> = {
  add: AddRoundedIcon,
  apartment: Apartment,
  archive: ArchiveOutlinedIcon,
  arrowLeft: WestRounded,
  arrowRight: EastRoundedIcon,
  attachFile: AttachFile,
  bold: FormatBoldRoundedIcon,
  bulletList: FormatListBulletedRoundedIcon,
  changeObject: SwapVerticalCircleOutlined,
  chatBubble: ChatBubbleOutline,
  check: Check,
  checkCircle: CheckCircle,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  close: CloseIcon,
  copyright: Copyright,
  createOutlined: CreateOutlinedIcon,
  deleteOutlined: DeleteOutlineRoundedIcon,
  devPanel: SettingsSuggest,
  edit: ModeEditOutlineOutlined,
  expandLess: ExpandLessRoundedIcon,
  expandMore: ExpandMoreRoundedIcon,
  filter: FilterList,
  filterOutlined: FilterAltOutlinedIcon,
  formatList: FormatListNumberedRoundedIcon,
  fullscreen: FullscreenRoundedIcon,
  help: HelpOutline,
  hotel: LocalHotelOutlined,
  italic: FormatItalicRoundedIcon,
  label: LabelOutlined,
  lock: LockOutlined,
  lockOpen: LockOpen,
  logout: Logout,
  more: MoreVert,
  notification: NotificationsNoneRounded,
  openInNew: OpenInNewRoundedIcon,
  preview: VisibilityOutlinedIcon,
  privacy: GppMaybeOutlined,
  profile: AccountCircle,
  refresh: RefreshRounded,
  replies: MessageOutlined,
  search: Search,
  send: SendRoundedIcon,
  speed: Speed,
  strikethrough: StrikethroughSRoundedIcon,
  template: ContentPasteOutlined,
  timer: TimerIcon,
  timerOutlined: TimerOutlinedIcon,
  topic: Topic,
  translate: Translate,
  tune: Tune,
  user: PersonOutline,
  userGroup: GroupsOutlined,
};

export const mappingMaterialSymbolIcon: Record<
  MuiSymbolIconName,
  FC<IconPropsExtended>
> = {
  clock: MaterialSymbolClockIcon,
  clockFilled: MaterialSymbolClockFilledIcon,
  download: MaterialSymbolDownloadIcon,
  error: MaterialSymbolErrorIcon,
  home: MaterialSymbolHomeIcon,
  info: MaterialSymbolInfoIcon,
  settings: MaterialSymbolSettingsIcon,
  warning: MaterialSymbolWarningIcon,
};

export type IconName = MuiIconName | MuiSymbolIconName;
