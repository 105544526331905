import { styled } from 'styled-components';

import devices from '../../styles/devices';

export const ContentWrapper = styled.section`
  font-size: var(--font-lg);
  padding: var(--spacing-medium) var(--spacing-medium) var(--spacing-huge);

  @media ${devices.tabletS} {
    padding: 0;
  }
`;
