import { ThemeProvider } from '@mui/material';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { setAppElement } from 'react-modal';
import { Provider as ReduxProvider } from 'react-redux';
import { Provider as GraphqlProvider } from 'urql';

import App from './app';

import ErrorBoundary from './components/ErrorBoundary';

import ToastContainer from './containers/toastContainer';
import client from './graphql/client';
import { reportCritical } from './services/reporting';
import store from './store/configureStore';
import { muiTheme } from './styles/mui';
import './assets/icons';

import './styles/css/index.css';

/**
 * Function to create app root component
 *
 * @returns App root component
 */
function Root() {
  return (
    <ErrorBoundary>
      <GraphqlProvider value={client}>
        <ReduxProvider store={store}>
          <ThemeProvider theme={muiTheme}>
            <App />
            <ToastContainer />
          </ThemeProvider>
        </ReduxProvider>
      </GraphqlProvider>
    </ErrorBoundary>
  );
}

const rootEl = document.getElementById('root');

if (rootEl === null) {
  reportCritical('Root: Root element not found.');
} else {
  const root = createRoot(rootEl);
  root.render(<Root />);

  setAppElement('#root');
}
