import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import breakpoints from '../../../constants/breakpoints';

import devices from '../../../styles/devices';
import Chevron from '../../Common/Chevron';
import MuiIcon from '../../MUI/MuiIcon';

// Chevron icon, visible on small screens and larger with touch
export const ChevronIcon = styled(Chevron)`
  flex-shrink: 0;
`;

// Edit Icon, for better usability, so user know if the section can be edited
export const EditIcon = styled(MuiIcon)`
  display: none;

  @media ${devices.tabletS} {
    display: block;
  }
`;

// Wrapper around the entire component
export const Wrapper = styled.section`
  align-items: center;
  border-top: var(--border-pale);
  padding: 0 0 var(--spacing-medium);

  /**
   * A fix for the scrolling issue on right sidebar for checklist overflow
   * pushing the page up
   * Resolved by creating another layer with position relative
   */
  position: relative;
`;

// Wrapper which can be clicked
export const WrapperClickable = styled(Link)`
  align-items: center;
  display: flex;
  gap: var(--spacing-tiny);
  padding: var(--spacing-medium);

  &:not(:first-child) {
    border-top: var(--border-pale);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-hover-light);
    }
  }

  /* Couldn't have used devices.tabletS because linter complains because I have "and" */
  @media (hover: hover) and (min-width: ${breakpoints.tabletS}px) {
    &:not(:focus-visible) ${ChevronIcon} {
      display: none;
    }
  }
`;

// Actual section content
export const Content = styled.article`
  flex-grow: 1;

  /* Cut off very long content so that chevron arrow doesn't get pushed out of alignment */
  min-width: 0;
`;

export const HeadingWrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeadingWrapperStatic = styled.header`
  font-size: var(--font-baseline);
  font-weight: var(--font-weight-heading);
  margin-bottom: var(--spacing-small);
  padding: var(--spacing-tiny) var(--spacing-medium);
`;

// Section heading
export const Heading = styled.h2`
  font-size: var(--font-baseline);
  font-weight: var(--font-weight-heading);
  margin: var(--spacing-tiny) 0;
`;

export const ChildrenWrapper = styled.div`
  padding: 0 var(--spacing-medium);
`;
