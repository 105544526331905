import React, { FC } from 'react';

import Icon, { NAMES } from '../../../Icon';

import { Props } from '../props';

type EmojiValues = 0 | 1 | 2;

/**
 * Mapping between the rating and the icon used
 * (in the backend, 0 is green and 2 is red,
 * and for icons, 1 is for red, 3 is for green)
 */
const mapping: Record<EmojiValues, string> = {
  0: NAMES.CONCIERGE__EMOJI_3_FULL,
  1: NAMES.CONCIERGE__EMOJI_2_FULL,
  2: NAMES.CONCIERGE__EMOJI_1_FULL,
};

/**
 * For feedback emoji field, we only show an appropriate icon
 *
 * @param props             Props passed to the component
 * @param props.field       The field to render
 * @param props.field.value Concierge field value
 * @returns                 The component itself
 */
const FieldEmoji: FC<Props> = ({ field: { value } }) => {
  const valueNumeric = parseInt(value ?? '0', 10) as EmojiValues;
  const iconName = mapping[valueNumeric];

  return <Icon name={iconName} />;
};

export default FieldEmoji;
