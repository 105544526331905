/**
 * @file Helper functions for toast messages
 */

import { isValidElement } from 'react';

import {
  ToastMessageModel,
  ToastMessageType,
} from '../../../models/toastMessage';
import { CssVariable } from '../../../styles/cssTypes';
import { MuiIconName, MuiSymbolIconName } from '../../MUI/MuiIcon/types';

import { DataProps } from './types';

const mappingColorBackground: Record<ToastMessageType, CssVariable> = {
  default: 'var(--color-toast-background-default)',
  error: 'var(--color-toast-background-error)',
  info: 'var(--color-toast-background-info)',
  success: 'var(--color-toast-background-success)',
  warning: 'var(--color-toast-background-warning)',
};

const mappingColorIcon: Record<ToastMessageType, CssVariable> = {
  default: 'var(--color-toast-icon-default)',
  error: 'var(--color-toast-icon-error)',
  info: 'var(--color-toast-icon-info)',
  success: 'var(--color-toast-icon-success)',
  warning: 'var(--color-toast-icon-warning)',
};

const mappingIcon: Record<ToastMessageType, MuiIconName | MuiSymbolIconName> = {
  default: 'notification',
  error: 'error',
  info: 'info',
  success: 'checkCircle',
  warning: 'warning',
};

/**
 * Get which variable to use for wrapper background color
 *
 * @param props Props passed to the wrapper
 * @returns     The variable to use
 */
export const getToastColorBackground = (props: DataProps): CssVariable => {
  return mappingColorBackground[props['data-message-type']];
};

/**
 * Get which variable to use for wrapper border color
 *
 * @param props Props passed to the wrapper
 * @returns     The variable to use
 */
export const getToastColorIcon = (props: DataProps): CssVariable => {
  return mappingColorIcon[props['data-message-type']];
};

/**
 * Get which icon to use for the toast message
 *
 * @param toastType Type of toast message
 * @returns         Icon name
 */
export const getToastIcon = (
  toastType: ToastMessageType,
): MuiIconName | MuiSymbolIconName => {
  return mappingIcon[toastType];
};

/**
 * Get whether the toast message has details
 *
 * @param details Additional details of the toast message
 * @returns       Whether it has details
 */
export const getHasDetails = (
  details: ToastMessageModel['details'],
): boolean => {
  if (typeof details === 'string') {
    return (details?.trim().length ?? 0) !== 0;
  }

  return isValidElement(details);
};
