/**
 * @file A user target language was updated, so we update it in the cache,
 */
import { Cache } from '@urql/exchange-graphcache';

import {
  MeDocument,
  MeQuery,
  MeQueryVariables,
  UserSettingsFragment,
} from '../../../../../../../generated/graphql';

/**
 * Merge original with updates and return
 *
 * @param original The old data
 * @param updates  The updates that occurred
 * @returns        Merged user data
 */
export const getUpdatedUser = <T extends UserSettingsFragment>(
  original: T,
  updates: UserSettingsFragment['targetLanguage'],
): T => ({
  ...original,
  targetLanguage: updates ?? original.targetLanguage,
});

/**
 * A user target language was updated, so we update it in the cache
 *
 * @param cache   urql cache object
 * @param updates The updates that occurred
 */
const usersTargetLanguageUpdate = (
  cache: Cache,
  updates: UserSettingsFragment['targetLanguage'],
): void => {
  cache.updateQuery<MeQuery, MeQueryVariables>(
    {
      query: MeDocument,
    },
    data => {
      if (data === null) {
        return null;
      }

      data.me = getUpdatedUser(data.me, updates);

      return data;
    },
  );
};

export default usersTargetLanguageUpdate;
