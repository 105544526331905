import { styled } from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  background-color: var(--color-background);
  border-radius: var(--border-radius-huge);
  box-shadow: var(--elevation-4);
  display: flex;
  height: var(--preference-loading-indicator-size);
  justify-content: center;
  left: 50%;
  position: absolute;
  top: var(--spacing-medium);
  transform: translate(-50%, 0);
  width: var(--preference-loading-indicator-size);
  z-index: var(--z-toast);
`;
