import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

import { ReservationsDocument } from '../../../../generated/graphql';
import useCurrentPath from '../../../../hooks/useCurrentPath';
import useIsTablet from '../../../../hooks/useIsTablet';
import { createReservationsPath } from '../../../../routes/paths/reservations';

import {
  createSettingsGroupsPath,
  createSettingsLabelsPath,
  createSettingsNotificationsEmailPath,
  createSettingsNotificationsEmailSinglePath,
  createSettingsNotificationsPushPath,
  createSettingsNotificationsPushSinglePath,
  createSettingsPath,
  createSettingsPeoplePath,
  createSettingsTemplateEditPath,
  createSettingsTemplatesPath,
  createSettingsTemplatesRecurringPath,
  createSettingsTopicsPath,
  createSettingsTranslationPath,
} from '../../../../routes/paths/settings';
import { ConfigCallback, LayoutConfig } from '../types';

import {
  getConfigGroups,
  getConfigLabels,
  getConfigNotificationsEmail,
  getConfigNotificationsPush,
  getConfigReservations,
  getConfigRoot,
  getConfigTemplates,
  getConfigTemplatesRecurring,
  getConfigTopics,
  getConfigTranslation,
  getConfigUsers,
} from './configs';

const pathReservations = createReservationsPath();

/**
 * The default config to use, if none found
 */
const configDefault: LayoutConfig = {
  buttons: [],
  floatingButton: null,
  icon: null,
  subtitle: null,
  title: null,
};

/**
 * Path -> Config mapping
 */
const configMapping = new Map<string, ConfigCallback>([
  [createSettingsGroupsPath(), getConfigGroups],
  [createSettingsLabelsPath(), getConfigLabels],
  [createSettingsNotificationsEmailPath(), getConfigNotificationsEmail],
  [createSettingsNotificationsEmailSinglePath(), getConfigNotificationsEmail],
  [createSettingsNotificationsPushPath(), getConfigNotificationsPush],
  [createSettingsNotificationsPushSinglePath(), getConfigNotificationsPush],
  [createSettingsPath(), getConfigRoot],
  [createSettingsPeoplePath(), getConfigUsers],
  [createSettingsTemplateEditPath(), getConfigTemplates],
  [createSettingsTemplatesPath(), getConfigTemplates],
  [createSettingsTemplatesRecurringPath(), getConfigTemplatesRecurring],
  [createSettingsTopicsPath(), getConfigTopics],
  [createSettingsTranslationPath(), getConfigTranslation],
]);

/**
 * React hook that calculates and returns layout config
 *
 * @returns Layout config
 */
const useLayoutConfig = (): LayoutConfig => {
  const currentPath = useCurrentPath();
  const isTablet = useIsTablet();
  const location = useLocation();
  const navigate = useNavigate();
  const [{ error }, executeQuery] = useQuery({
    pause: true,
    query: ReservationsDocument,
    variables: {},
  });

  if (currentPath === null) {
    return configDefault;
  }

  // Reservations executes a query on button click,
  // so we can't generate config like for others
  if (currentPath === pathReservations) {
    if (error) {
      reportError(error);
      navigate('/');
    }

    return getConfigReservations(() =>
      executeQuery({ requestPolicy: 'cache-and-network' }),
    );
  }

  return (
    configMapping.get(currentPath)?.(location, navigate, isTablet) ??
    configDefault
  );
};

export default useLayoutConfig;
