import { css, styled } from 'styled-components';

import { AttachmentMode } from '../../../models/attachment';
import { visuallyHidden } from '../../../styles/common';
import devices from '../../../styles/devices';

import ButtonCloseCommon from '../../Common/ButtonClose';
import IconCommon from '../../Common/Icon';

import { Wrapper as RibbonWrapper } from './AttachmentRibbon/styled';

type WrapperProps = {
  'data-mode': AttachmentMode;
};

/**
 * Get styles for current mode
 *
 * @param props Props passed to the component
 * @returns     Styles for current mode
 */
const getStylesForMode = (props: WrapperProps) => {
  const mode = props['data-mode'];

  if (mode === 'details') {
    return css`
      border: var(--border-dark);
      border-radius: var(--border-radius-tiny);
      justify-content: flex-start;
      padding: calc(var(--spacing-small) / 2) 0;
      width: 160px;

      @media (hover: hover) {
        &:hover {
          background-color: var(--color-background-selected);
        }
      }

      @media ${devices.tabletS} {
        width: 180px;
      }
    `;
  }

  if (mode === 'detailsEdit') {
    return css`
      transition: background-color var(--preference-transition-duration-normal);

      @media (hover: hover) {
        &:hover {
          background-color: var(--color-background-selected);
        }
      }

      @media ${devices.tabletS} {
        padding: var(--spacing-tiny);
      }
    `;
  }

  return css`
    border: var(--border-dark);
    border-radius: var(--border-radius-tiny);
    justify-content: flex-start;
    padding: calc(var(--spacing-small) / 2) var(--spacing-small);
    width: 160px;

    @media ${devices.tabletS} {
      width: 180px;
    }
  `;
};

export const ActionWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate3d(50%, -50%, 0);
`;

export const ButtonClose = styled(ButtonCloseCommon)`
  background-color: var(--color-background-avatar);
  border-radius: 50%;
  color: var(--color-hover-light);
`;

export const ButtonPreview = styled.button`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  font-size: var(--font-baseline);
  overflow: hidden;
`;

export const Icon = styled(IconCommon)`
  flex-shrink: 0;
`;

export const Title = styled.p`
  flex-grow: 1;
  margin: 0 var(--spacing-tiny);
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
`;

export const Wrapper = styled.div<WrapperProps>`
  align-items: center;
  background-color: var(--color-background);
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  ${props => getStylesForMode(props)}
  position: relative;
  &:hover ${RibbonWrapper} {
    display: flex;
  }
`;

// DetailsEditFragment
export const DetailsEditFragmentWrapper = styled.div`
  display: flex;
  gap: var(--spacing-small);
`;

/**
 * Hidden link used in PreviewFragment
 */
export const HiddenLink = styled.a`
  ${visuallyHidden}
`;
