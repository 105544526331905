import { Collapse } from '@mui/material';
import React, { FC, useState } from 'react';

import { TOAST__MESSAGE_DETAILS_LENGTH } from '../../../constants/preferences';

import { ToastMessageModelWithType } from '../../../models/toastMessage';
import truncateText from '../../../utils/string/truncateText';

import ToastFooter from './ToastFooter';
import ToastToggle from './ToastToggle';
import { getHasDetails } from './helpers';
import * as Styled from './styled';

export type Props = Omit<
  ToastMessageModelWithType,
  'duration' | 'isRef' | 'text'
> & {
  requestCloseToast: () => void;
};

/**
 * Details of the toast, if available + footer with action buttons
 *
 * @param props                   Props passed to the component
 * @param props.customAction      Action to show
 * @param props.details           Details to show
 * @param props.requestCloseToast Callback to close the toast
 * @param props.shouldShowClose   Whether the close button should be shown
 * @param props.type              The type of the toast message
 * @returns                       The component itself
 */
const ToastDetailsWithFooter: FC<Props> = ({
  customAction,
  details,
  requestCloseToast,
  shouldShowClose,
  type,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasDetails = getHasDetails(details);

  /**
   * Expand or collapse the additional text
   */
  const toggleDetails = () => {
    setIsExpanded(prev => !prev);
  };

  return (
    <>
      {hasDetails && (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit={true}>
          <Styled.ToastCardCollapseContent>
            {typeof details === 'string'
              ? truncateText(TOAST__MESSAGE_DETAILS_LENGTH, details)
              : details}
          </Styled.ToastCardCollapseContent>
        </Collapse>
      )}
      <ToastFooter
        customAction={customAction}
        requestCloseToast={requestCloseToast}
        shouldShowClose={shouldShowClose}
      >
        {hasDetails && (
          <ToastToggle
            isExpanded={isExpanded}
            requestToggleAdditionalText={toggleDetails}
            type={type}
          />
        )}
      </ToastFooter>
    </>
  );
};

export default ToastDetailsWithFooter;
