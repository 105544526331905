import { SvgIconOwnProps } from '@mui/material';
import Tippy from '@tippyjs/react';
import React, { FC } from 'react';

import useFocus from '../../../hooks/useFocus';

import MuiIcon from '../../MUI/MuiIcon';
import { MuiIconName, MuiSymbolIconName } from '../../MUI/MuiIcon/types';
import { NAMES } from '../Icon';

import * as Styled from './styled';

export type Props = {
  className?: string;
  color?: string;
  colorFocus?: string;
  colorHover?: string;
  fontSize?: SvgIconOwnProps['fontSize'];
  iconColor?: SvgIconOwnProps['color'];
  iconHeight?: number;
  iconName: MuiIconName | MuiSymbolIconName;
  iconPosition?: 'left' | 'right';
  iconWidth?: number;
  label: string;
  onClick?: () => void;
  text?: string;
  url: string;
};

const defaultColor = 'currentColor';

/**
 * Link with icon and optional text
 *
 * @param props              Props passed to the component
 * @param props.className    Styled-components generated class name, needed for styling
 * @param props.color        Color of the link button
 * @param props.colorFocus   Color of the icon when the button is focused
 * @param props.colorHover   Color of the icon when the button is hovered
 * @param props.fontSize     Size prop defined in Mui api, can be - SvgIconProps['fontSize']: 'inherit' | 'large' | 'small'
 * @param props.iconColor    Color of the icon
 * @param props.iconHeight   The height of the included icon (button itself will be the same size)
 * @param props.iconName     Which icon to show within the button
 * @param props.iconPosition Icon position in relation to text
 * @param props.iconWidth    The width of the included icon (button itself will be the same size)
 * @param props.label        Aria label for the button (since there's no text, just the icon)
 * @param props.onClick      Click callback, not needed for submit buttons
 * @param props.url          Url to link to
 * @param props.text         Text shown
 * @returns                  The component itself
 */
const LinkIconWithText: FC<Props> = ({
  className,
  color = defaultColor,
  fontSize = 'small',
  iconColor,
  iconHeight,
  iconName,
  iconPosition = 'left',
  iconWidth,
  label,
  onClick,
  text,
  url,
}) => {
  const [, bind] = useFocus();

  return (
    <Tippy content={label} touch={false}>
      <span>
        <Styled.Link
          aria-label={label}
          className={className}
          data-color={color}
          data-position={iconPosition}
          onClick={onClick}
          to={url}
          {...bind}
        >
          <MuiIcon
            color={iconColor}
            fontSize={fontSize}
            height={iconHeight}
            icon={iconName}
            width={iconWidth}
          />
          {text}
        </Styled.Link>
      </span>
    </Tippy>
  );
};

export { NAMES };
export default LinkIconWithText;
