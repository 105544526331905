import { SvgIconOwnProps, SvgIconProps } from '@mui/material';
import React, { FC } from 'react';

import translate from '../../../i18n/translate';
import ButtonIcon from '../ButtonIcon';

export type Props = {
  className?: HTMLElement['className'];
  color?: SvgIconOwnProps['color'];
  fontSize?: SvgIconProps['fontSize'];
  iconColor?: SvgIconOwnProps['color'];
  iconSize?: number;
  label?: string;
  onClick?: () => void;
};

/**
 * Default icon size
 */
export const ICON_SIZE = 15;

/**
 * Close button
 *
 * @param props           The props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.color     Icon color
 * @param props.fontSize  Size prop defined in Mui api, can be - SvgIconProps['fontSize']: 'inherit' | 'large' | 'small'
 * @param props.label     Button label
 * @param props.iconColor Color of the icon
 * @param props.iconSize  Icon size
 * @param props.onClick   Click callback for the button
 * @returns               The component itself
 */
const ButtonClose: FC<Props> = ({
  className,
  color = 'secondary',
  fontSize,
  iconColor,
  iconSize = ICON_SIZE,
  label = translate('GENERAL__CLOSE'),
  onClick,
}) => {
  return (
    <ButtonIcon
      className={className}
      color={color}
      fontSize={fontSize}
      iconColor={iconColor}
      iconHeight={iconSize}
      iconName="close"
      iconWidth={iconSize}
      label={label}
      onClick={onClick}
    />
  );
};

export default ButtonClose;
