import { styled } from 'styled-components';

type ImgWrapperProps = {
  'data-size': number;
  'data-reduced-opacity'?: boolean;
};

export const Img = styled.img`
  border-radius: var(--border-radius-tiny);

  /**
   * if the image fails to load, prevent alt text from going over surrounding elements
   */
  overflow: hidden;
`;

export const ImgWrapper = styled.div<ImgWrapperProps>`
  border-radius: var(--border-radius-tiny);
  flex-shrink: 0;
  height: ${props => props['data-size']}px;
  position: relative;
  width: ${props => props['data-size']}px;

  &::after {
    background-color: var(--color-background-avatar-inactive);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: ${props => (props['data-reduced-opacity'] ? '0.5' : '0')};
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
