import React, { FC } from 'react';

import * as Styled from './styled';

export type Props = {
  fileNames: string[];
  title: string;
};

/**
 * Details of the toast message when the attachment upload fails
 *
 * @param props           Props passed to the component
 * @param props.fileNames The names of the files that couldn't be uploaded
 * @param props.title     The title of the error
 * @returns               The component itself
 */
const ToastDetailsAttachmentUploadError: FC<Props> = ({ fileNames, title }) => {
  return (
    <span>
      <p>{title}</p>
      <ul>
        {fileNames.map((fileName, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Styled.Li key={index}>{fileName}</Styled.Li>
        ))}
      </ul>
    </span>
  );
};

export default ToastDetailsAttachmentUploadError;
