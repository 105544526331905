import React, { FC } from 'react';

import translate from '../../../i18n/translate';

import * as Styled from './styled';

export type Props = {
  className?: HTMLButtonElement['className'];
  isDisabled?: HTMLButtonElement['disabled'];
  onClick?: () => void;
  shouldShowIcon?: boolean;
  type?: 'button' | 'submit';
};

const ICON_SIZE = 20;

/**
 * Button wrapper that returns reused Button and Icon components combined with string Delete,
 * with onClick callback
 *
 * @param props                The props passed to the component
 * @param props.className      styled-components generated class name, needed for styling
 * @param props.isDisabled     Whether to make the button disabled
 * @param props.onClick        Button click callback, can be omitted when type is submit
 * @param props.type           <button> type attribute
 * @param props.shouldShowIcon Whether the trashcan icon should be shown
 * @returns                    The component itself
 */
const ButtonDelete: FC<Props> = ({
  className,
  isDisabled = false,
  onClick,
  shouldShowIcon = false,
  type = 'button',
}) => {
  const buttonLabel = translate('GENERAL__DELETE');

  if (shouldShowIcon) {
    return (
      <Styled.ButtonIconWithText
        color="var(--color-button-danger)"
        iconColor="warning"
        iconName="deleteOutlined"
        iconPosition="right"
        iconWidth={ICON_SIZE}
        label={buttonLabel}
        onClick={onClick}
        text={buttonLabel}
      />
    );
  }

  return (
    <Styled.ButtonDelete
      aria-haspopup="true"
      className={className}
      isDisabled={isDisabled}
      onClick={onClick}
      type={type}
    >
      {buttonLabel}
    </Styled.ButtonDelete>
  );
};

export default ButtonDelete;
